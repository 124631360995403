import React from 'react';
import {
  Button,
  Card,
  CardMedia,
  CardActionArea,
  CardActions,
  Checkbox,
  Chip,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  RadioGroup,
  FormControlLabel,
  Box,
  Tooltip,
} from '@material-ui/core';
import styled from 'styled-components';

// `tagged` has to be a string, or a warning will be shown in dev console
const StyledCardActions = styled(CardActions)`
  background-color: ${({ tagged, pagetype }) =>
    tagged === 'true' && pagetype === 'UNTAGGED' ? `lightgreen` : `white`};
  overflow-x: hidden;
  overflow-y: auto;
`;

const StyledGridForMenuItems = styled(Grid)`
  height: 400px;
`;

export class AirlineCard extends React.Component {
  state = {
    imageMaximized: false,
  };

  expandImage = () => {
    this.setState({ imageMaximized: true });
  };

  closeExpandedImage = () => {
    this.setState({ imageMaximized: false });
  };

  removeMenuItemFromWaste = (index) => {
    const { waste, wasteCardIndex, changeWasteParameterValue } = this.props;
    const arrMenuItemToBeUpdated = waste.arrMenuItem;
    arrMenuItemToBeUpdated.splice(index, 1);

    changeWasteParameterValue([
      this.createUpdateObject('arrMenuItem', arrMenuItemToBeUpdated, wasteCardIndex),
    ]);
  };

  // Make an update object to be passed up to perform waste parameter value updates
  createUpdateObject = (parameterName, parameterValue, wasteCardIndex) => ({
    parameterName,
    parameterValue,
    wasteCardIndex,
  });

  addMenuItemToWaste = (selectedMenuItemForSelection, wasteThrownTime) => {
    const { waste, wasteCardIndex, changeWasteParameterValue, arrMenuItem } = this.props;
    const arrMenuItemToBeUpdated = waste.arrMenuItem;

    // arrMenuItem contains all the menu items valid within the selected date range for the service the waste belong to.
    // Find all menu items with the same name as the selected menuItemName. Then filter out those with expiryDate before the waste's thrown date.
    const arrPossiblySelectedMenuItem = arrMenuItem.filter((menuItem) => {
      if (menuItem.name === selectedMenuItemForSelection.value) {
        if (
          menuItem.expiryDate === null ||
          new Date(menuItem.expiryDate) > new Date(wasteThrownTime)
        ) {
          return menuItem;
        }
      }
      return null;
    });
    // If there are more than 1 menu item with the same name, sort them accordingly to the expiryDate. The first menu item will have the expiryDate
    // beyond but closest to the waste's thrown date, so that will be the selected menu item.
    if (arrPossiblySelectedMenuItem.length > 1) {
      arrPossiblySelectedMenuItem.sort((menuItemA, menuItemB) => {
        if (menuItemA < menuItemB) {
          return 0;
        }
        return 1;
      });
    }
    arrMenuItemToBeUpdated.push(arrPossiblySelectedMenuItem[0]);
    changeWasteParameterValue([
      this.createUpdateObject('arrMenuItem', arrMenuItemToBeUpdated, wasteCardIndex),
    ]);
  };

  render() {
    const { imageMaximized } = this.state;
    const {
      waste,
      arrStationMenuItemForSelection,
      changeWasteParameterValue,
      wasteCardIndex,
      pageType,
    } = this.props;
    // Selection for { value: 'All', label: 'All Stations' } is not required for airline card
    const arrMenuItemForSelectionForAirlineCard =
      arrStationMenuItemForSelection[0].arrMenuItemForSelection.slice(1);
    return (
      <React.Fragment>
        <Card>
          {/* Image */}
          <CardActionArea>
            <Tooltip
              title={
                <Box style={{ padding: '10px' }}>
                  <Typography>Location: {waste.locationName}</Typography>

                  <Typography>Service: {waste.serviceName}</Typography>

                  <Typography>Service ID: {waste.serviceId}</Typography>

                  <Typography>
                    Throw Time: {new Date(waste.time).toLocaleString('en-SG')}
                  </Typography>

                  <Typography>Bin Serial: {waste.serialNumber}</Typography>

                  <Typography>Waste ID: {waste.wasteId}</Typography>

                  <Typography>Image ID: {waste.imageId}</Typography>

                  <Typography>RFID Tag: {waste.inputTag}</Typography>
                </Box>
              }
            >
              <CardMedia
                component="img"
                height="100%"
                image={waste.imageURL}
                onClick={this.expandImage}
              />
            </Tooltip>
          </CardActionArea>

          <StyledCardActions
            tagged={waste.arrMenuItem.length !== 0 && !waste.doNotTag ? 'true' : 'false'}
            pagetype={pageType}
          >
            <StyledGridForMenuItems container spacing={2}>
              {/* Suitability */}
              <Grid item xs={12}>
                <RadioGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={waste.doNotTag}
                        onChange={() =>
                          changeWasteParameterValue([
                            this.createUpdateObject('doNotTag', !waste.doNotTag, wasteCardIndex),
                          ])
                        }
                        color="primary"
                      />
                    }
                    label="Don't Tag"
                  />
                </RadioGroup>
              </Grid>

              {/* Currently identified menu item(s) for waste */}
              <Grid item direction="row" xs={12}>
                {waste.arrMenuItem.map((menuItem, index) => {
                  return (
                    <Chip
                      label={menuItem.name}
                      onDelete={() => this.removeMenuItemFromWaste(index)}
                      style={{ fontSize: 10.5 }}
                    />
                  );
                })}
              </Grid>

              {/* Menu items */}
              <Grid item xs={9}>
                {arrMenuItemForSelectionForAirlineCard.map((menuItemForSelection) => {
                  return (
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => this.addMenuItemToWaste(menuItemForSelection)}
                    >
                      <Typography style={{ fontSize: 11.5 }}>
                        {menuItemForSelection.value}
                      </Typography>
                    </Button>
                  );
                })}
              </Grid>
            </StyledGridForMenuItems>
          </StyledCardActions>
        </Card>

        {/* Image modal */}
        <Dialog
          fullWidth={false}
          maxWidth="xl"
          open={imageMaximized}
          scroll="body"
          onClose={this.closeExpandedImage}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogContent>
            <img src={waste.imageURL} alt="" />
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}
