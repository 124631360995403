import React from 'react';
import styled from 'styled-components';

import Waste from './Tabs/Waste';
import Menu from './Tabs/Menu';
import jwt_decode from 'jwt-decode';
import SideBar from './Layout/SideBar';
import DownloadPopup from './Popup/DownloadPopup';
import TopBar from './Layout/TopBar';
import { Divider } from '@material-ui/core';
import { addDays } from 'date-fns';

const TopBarStyle = styled(TopBar)`
  height: 75px;
  z-index: 199;
  position: relative;
`;

const MainContainer = styled.div`
  height: 100%;
  background-color: rgba(130, 136, 121, 0.15);
  transition: padding-left 0.5s ease-in-out;
`;

const ContentContainer = styled.div`
  width: calc(100% - 250px);
  margin-left: 250px;
`;

const token = 'Bearer ' + sessionStorage.getItem('token');

/**
 * Holds all components of the remote tagging platform.
 * Holds: Top Bar, Side Bar, Downlaod Popup, Untagged, Tagged and Menu components.
 */
export default class Home extends React.Component {
  controller = new AbortController();
  signal = this.controller.signal;

  constructor(props) {
    super(props);
    this.state = {
      currentTab: 'UNTAGGED',
      startDateTime: addDays(new Date(), -30),
      endDateTime: new Date(),
      showDownloadPopup: false,
      userId: parseInt(sessionStorage.getItem('userId')),

      // Top bar drop down values
      arrCompanyService: JSON.parse(sessionStorage.getItem('arrCompanyService')),
      arrRestaurantServiceForFiltering: [],
      arrLocationServiceForFiltering: [],
      arrServiceForFiltering: [],

      // Top bar selected values
      selectedCompanyName: '',
      selectedRestaurantName: '',
      selectedLocationName: '',
      selectedServiceName: '',
      selectRestaurantNow: false,
      selectLocationNow: false,
      selectServiceNow: false,

      arrPrevSelectedServiceCompany: [],
      arrSelectedServiceCompany: [],
    };
  }

  componentDidMount = () => {
    this.validateAuthorization();
    this.fetchArrCompanyService();
  };

  componentWillUnmount = () => {
    this.controller.abort();
  };

  validateAuthorization = async () => {
    const { history } = this.props;
    try {
      const { userId, arrSelectedServiceCompany } = this.state;
      if (sessionStorage.getItem('token')) {
        const response = await fetch('/api/validate-session', {
          signal: this.signal,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + sessionStorage.getItem('token'),
          },
          body: JSON.stringify({
            userId,
          }),
        });
        if (response.status !== 200) {
          // Note (5 Jul 23): Remove for now, since there is only one user and we are shifting to Vision-UI
          // await fetch('/api/unlock-service', {
          //   signal: this.signal,
          //   method: 'POST',
          //   headers: {
          //     'Content-Type': 'application/json',
          //     Authorization: 'Bearer ' + token,
          //   },
          //   body: JSON.stringify({
          //     userId,
          //     arrServiceCompany: arrSelectedServiceCompany,
          //   }),
          // });
          sessionStorage.clear();
          history.push('/');
        } else {
          const decoded = jwt_decode(sessionStorage.getItem('token'));
          this.setState({
            email: decoded.email,
          });
        }
      } else {
        history.push('/');
      }
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.log(`Caught promise: ${error}`);
      }
      history.push('/');
    }
  };

  fetchArrCompanyService = async () => {
    const { arrCompanyService } = this.state;
    if (arrCompanyService.length === 0) {
      try {
        const { userId } = this.state;
        const response = await fetch('/api/fetch-arr-company-service', {
          signal: this.signal,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
          body: JSON.stringify({
            userId,
          }),
        });
        const responseJson = await response.json();
        this.setState({
          arrCompanyService: responseJson.arrCompanyService.sort((a, b) => a.name > b.name),
        });
      } catch (error) {
        if (error.name !== 'AbortError') {
          console.error(error);
        }
      }
    }
  };

  changeCompanyName = (event) => {
    const { arrCompanyService } = this.state;
    const { value } = event.target;

    const foundCompanyService = arrCompanyService.find(
      (companyService) => companyService.name === value
    );
    this.setState({
      arrRestaurantServiceForFiltering: foundCompanyService.arrRestaurantService.sort(
        (a, b) => a.name > b.name
      ),
      selectedCompanyName: value,
      selectedRestaurantName: '',
      selectedLocationName: '',
      selectedServiceName: '',
      selectRestaurantNow: true,
    });
  };

  changeRestaurantName = (event) => {
    const { arrRestaurantServiceForFiltering } = this.state;
    const { value } = event.target;

    const foundRestaurantService = arrRestaurantServiceForFiltering.find(
      (restaurantService) => restaurantService.name === value
    );
    this.setState({
      arrLocationServiceForFiltering: foundRestaurantService.arrLocationService.sort(
        (a, b) => a.name > b.name
      ),
      selectedRestaurantName: value,
      selectedLocationName: '',
      selectedServiceName: '',
      selectRestaurantNow: false,
      selectLocationNow: true,
    });
  };

  changeLocationName = (event) => {
    const { arrLocationServiceForFiltering } = this.state;
    const { value } = event.target;
    let arrServiceForFiltering = [];
    // If selectedServiceName is 'All', the only option for service selection is 'All' too.
    if (value !== 'All') {
      const foundLocationService = arrLocationServiceForFiltering.find(
        (locationService) => locationService.name === value
      );
      arrServiceForFiltering = foundLocationService.arrService.sort((a, b) => a.name > b.name);
    }

    this.setState({
      arrServiceForFiltering,
      selectedLocationName: value,
      selectedServiceName: '',
      selectLocationNow: false,
      selectServiceNow: true,
    });
  };

  changeServiceName = (event) => {
    const { value } = event.target;

    this.setState(
      {
        selectedServiceName: value,
        selectServiceNow: false,
      },
      this.getArrServiceCompany(value)
    );
  };

  changeTabOnSideBar = (selectedTab) => {
    this.setState({
      currentTab: selectedTab,
    });
  };

  /**
   * Get arrServiceCompany from the selected company, restaurant, location and service. This array is useful as it makes it
   * easier to pass to Backend for updates for service and waste.
   */
  getArrServiceCompany = (selectedServiceName) => {
    const {
      arrCompanyService,
      arrRestaurantServiceForFiltering,
      arrLocationServiceForFiltering,
      arrServiceForFiltering,
      arrSelectedServiceCompany,
      selectedCompanyName,
      selectedRestaurantName,
      selectedLocationName,
    } = this.state;
    const arrPrevSelectedServiceCompany = arrSelectedServiceCompany;
    // Find the selected companyService to get the company attributes, and restaurantService to get the restaurant attributes
    // to form restaurantCompany, which provides part of the attributes for the eventual serviceCompany objects.
    const foundCompanyService = arrCompanyService.find(
      (companyService) => companyService.name === selectedCompanyName
    );
    const foundRestaurantService = arrRestaurantServiceForFiltering.find(
      (restaurantService) => restaurantService.name === selectedRestaurantName
    );
    const restaurantCompany = {
      companyName: foundCompanyService.name,
      companyId: foundCompanyService.companyId,
      isAirline: foundCompanyService.isAirline,
      restaurantId: foundRestaurantService.restaurantId,
      restaurantName: foundRestaurantService.name,
      isValid: foundRestaurantService.isValid,
    };
    const arrServiceCompany = [];
    // If selectedLocationName is 'All', selectedServiceName will be 'All' too
    if (selectedLocationName === 'All') {
      arrLocationServiceForFiltering.forEach((locationService) => {
        locationService.arrService.forEach((service) => {
          const serviceCompany = {
            ...restaurantCompany,
            locationId: locationService.locationId,
            restaurantId: locationService.restaurantId,
            locationName: locationService.name,
            ...service,
          };
          // Replace name with serviceName to differentiate between companyName, restaurantName, locationName and serviceName
          delete serviceCompany.name;
          serviceCompany.serviceName = service.name;
          arrServiceCompany.push(serviceCompany);
        });
      });
      // If selectedLocationName is not 'All', check if selectedServiceName is 'All'
    } else {
      const foundLocationService = arrLocationServiceForFiltering.find(
        (locationService) => locationService.name === selectedLocationName
      );
      const locationCompany = {
        ...restaurantCompany,
        locationId: foundLocationService.locationId,
        restaurantId: foundLocationService.restaurantId,
        locationName: foundLocationService.name,
      };
      if (selectedServiceName === 'All') {
        arrServiceForFiltering.forEach((service) => {
          const serviceCompany = {
            ...locationCompany,
            ...service,
          };
          // Replace name with serviceName to differentiate between companyName, restaurantName, locationName and serviceName
          delete serviceCompany.name;
          serviceCompany.serviceName = service.name;
          arrServiceCompany.push(serviceCompany);
        });
      } else {
        const foundService = arrServiceForFiltering.find(
          (service) => service.name === selectedServiceName
        );
        const serviceCompany = {
          ...locationCompany,
          ...foundService,
        };
        // Replace name with serviceName to differentiate between companyName, restaurantName, locationName and serviceName
        delete serviceCompany.name;
        serviceCompany.serviceName = selectedServiceName;
        arrServiceCompany.push(serviceCompany);
      }
    }
    this.setState({
      arrPrevSelectedServiceCompany,
      arrSelectedServiceCompany: arrServiceCompany,
    });
  };

  logOut = async () => {
    const { history } = this.props;
    // Note (5 Jul 23): Remove for now, since there is only one user and we are shifting to Vision-UI
    // try {
    //   const { userId, arrSelectedServiceCompany } = this.state;
    //   await fetch('/api/unlock-service', {
    //     signal: this.signal,
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //       Authorization: 'Bearer ' + token,
    //     },
    //     body: JSON.stringify({
    //       userId,
    //       arrServiceCompany: arrSelectedServiceCompany,
    //     }),
    //   });
    // } catch (error) {
    //   console.error(error);
    // }
    sessionStorage.clear();
    history.push('/');
  };

  changeShowDownloadPopup = () => {
    this.setState({
      showDownloadPopup: !this.state.showDownloadPopup,
    });
  };

  // To reset the parameter selected to reload the page
  resetSelectedParameter = () => {
    this.setState({
      selectedCompanyName: '',
      selectedRestaurantName: '',
      selectedLocationName: '',
      selectedServiceName: '',
      selectRestaurantNow: false,
      selectLocationNow: false,
      selectServiceNow: false,
    });
  };

  render() {
    const {
      arrPrevSelectedServiceCompany,
      arrSelectedServiceCompany,
      arrCompanyService,
      arrRestaurantServiceForFiltering,
      arrLocationServiceForFiltering,
      arrServiceForFiltering,
      currentTab,
      selectedCompanyName,
      selectedRestaurantName,
      selectedLocationName,
      selectedServiceName,
      selectRestaurantNow,
      selectLocationNow,
      selectServiceNow,
      showDownloadPopup,
    } = this.state;
    const isSpecificServiceSelected = selectedServiceName !== 'All' && selectedServiceName !== '';
    const isServiceSelected = selectedServiceName !== '';
    return (
      <MainContainer>
        <TopBarStyle
          changeCompanyName={this.changeCompanyName}
          changeRestaurantName={this.changeRestaurantName}
          changeLocationName={this.changeLocationName}
          changeServiceName={this.changeServiceName}
          arrCompanyService={arrCompanyService}
          arrRestaurantServiceForFiltering={arrRestaurantServiceForFiltering}
          arrLocationServiceForFiltering={arrLocationServiceForFiltering}
          arrServiceForFiltering={arrServiceForFiltering}
          selectedCompanyName={selectedCompanyName}
          selectedRestaurantName={selectedRestaurantName}
          selectedLocationName={selectedLocationName}
          selectedServiceName={selectedServiceName}
          logOut={this.logOut}
          selectRestaurantNow={selectRestaurantNow}
          selectLocationNow={selectLocationNow}
          selectServiceNow={selectServiceNow}
        />

        <Divider />

        <SideBar
          changeTabOnSideBar={this.changeTabOnSideBar}
          changeShowDownloadPopup={this.changeShowDownloadPopup}
        />

        <DownloadPopup
          showDownloadPopup={showDownloadPopup}
          closeDownloadPopup={this.changeShowDownloadPopup}
          arrCompanyService={arrCompanyService}
        />

        <ContentContainer>
          {currentTab === 'UNTAGGED' ? (
            <Waste
              arrPrevSelectedServiceCompany={arrPrevSelectedServiceCompany}
              arrSelectedServiceCompany={arrSelectedServiceCompany}
              selectedCompanyName={selectedCompanyName}
              selectedRestaurantName={selectedRestaurantName}
              selectedLocationName={selectedLocationName}
              selectedServiceName={selectedServiceName}
              isServiceSelected={isServiceSelected}
              pageType={currentTab}
              resetSelectedParameter={this.resetSelectedParameter}
            />
          ) : currentTab === 'TAGGED' ? (
            <Waste
              arrPrevSelectedServiceCompany={arrPrevSelectedServiceCompany}
              arrSelectedServiceCompany={arrSelectedServiceCompany}
              selectedCompanyName={selectedCompanyName}
              selectedRestaurantName={selectedRestaurantName}
              selectedLocationName={selectedLocationName}
              selectedServiceName={selectedServiceName}
              isServiceSelected={isServiceSelected}
              pageType={currentTab}
            />
          ) : currentTab === 'MENU' ? (
            <Menu
              arrServiceForFiltering={arrServiceForFiltering}
              selectedCompanyName={selectedCompanyName}
              selectedRestaurantName={selectedRestaurantName}
              selectedLocationName={selectedLocationName}
              selectedServiceName={selectedServiceName}
              isSpecificServiceSelected={isSpecificServiceSelected}
            />
          ) : (
            <Waste
              arrSelectedServiceCompany={arrSelectedServiceCompany}
              selectedCompanyName={selectedCompanyName}
              selectedRestaurantName={selectedRestaurantName}
              selectedLocationName={selectedLocationName}
              selectedServiceName={selectedServiceName}
              isServiceSelected={isServiceSelected}
              pageType={currentTab}
            />
          )}
        </ContentContainer>
      </MainContainer>
    );
  }
}
