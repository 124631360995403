import React from 'react';

// Material UI components
import { withStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// Icons
import LogOutIcon from '../images/LogOutIcon.svg';

const styles = (theme) => ({
  // Wrappers
  container: {
    paddingLeft: '10px',
    overflow: 'visible',
    position: 'sticky',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#dee3db',
    height: '67px',
  },
  companyInfo: {
    display: 'flex',
    left: '250px',
    position: 'relative',
  },

  // Form
  styledFormControl: {
    marginLeft: '8px',
    width: '230px',
  },
  logoutIcon: {
    width: '24px',
    height: '24px',
  },
});

class TopBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openLocationNow: false,
      openRestaurantNow: false,
      openServiceNow: false,
    };
  }

  render() {
    const {
      changeCompanyName,
      changeRestaurantName,
      changeLocationName,
      changeServiceName,
      logOut,
      arrCompanyService,
      arrRestaurantServiceForFiltering,
      arrLocationServiceForFiltering,
      arrServiceForFiltering,
      selectedCompanyName,
      selectedRestaurantName,
      selectedLocationName,
      selectedServiceName,
      selectServiceNow,
      selectLocationNow,
      selectRestaurantNow,
      classes,
    } = this.props;

    const { openServiceNow, openRestaurantNow, openLocationNow } = this.state;
    const arrCompanyNameForFiltering = arrCompanyService.map((companyService, index) => {
      return (
        <MenuItem key={index} value={companyService.name}>
          {companyService.name}
        </MenuItem>
      );
    });

    const arrRestaurantNameForFiltering = arrRestaurantServiceForFiltering.map(
      (restaurantService, index) => {
        return (
          <MenuItem key={index} value={restaurantService.name}>
            {restaurantService.name}
          </MenuItem>
        );
      }
    );

    const arrLocationNameForFiltering = arrLocationServiceForFiltering.map(
      (locationService, index) => {
        return (
          <MenuItem key={index} value={locationService.name}>
            {locationService.name}
          </MenuItem>
        );
      }
    );

    const arrServiceNameForFiltering = arrServiceForFiltering.map((service, index) => {
      return (
        <MenuItem key={index} value={service.name}>
          {service.name}
        </MenuItem>
      );
    });

    return (
      <div className={classes.container}>
        <div className={classes.companyInfo}>
          <FormControl className={classes.styledFormControl}>
            <InputLabel>Company</InputLabel>
            <Select value={selectedCompanyName} onChange={changeCompanyName}>
              {arrCompanyNameForFiltering}
            </Select>
          </FormControl>
          {selectedCompanyName === '' || (
            <FormControl className={classes.styledFormControl}>
              <InputLabel>Restaurant</InputLabel>
              <Select
                open={selectRestaurantNow || openRestaurantNow}
                value={selectedRestaurantName}
                onOpen={() => {
                  this.setState({ openRestaurantNow: true });
                }}
                onClose={() => {
                  this.setState({ openRestaurantNow: false });
                }}
                onChange={changeRestaurantName}
              >
                {arrRestaurantNameForFiltering}
              </Select>
            </FormControl>
          )}
          {selectedRestaurantName === '' || (
            <FormControl className={classes.styledFormControl}>
              <InputLabel>Location</InputLabel>
              <Select
                open={selectLocationNow || openLocationNow}
                value={selectedLocationName}
                onOpen={() => {
                  this.setState({ openLocationNow: true });
                }}
                onClose={() => {
                  this.setState({ openLocationNow: false });
                }}
                onChange={changeLocationName}
              >
                <MenuItem key={-1} value="All">
                  {' '}
                  All{' '}
                </MenuItem>
                {arrLocationNameForFiltering}
              </Select>
            </FormControl>
          )}
          {selectedLocationName === '' || (
            <FormControl className={classes.styledFormControl}>
              <InputLabel>Service</InputLabel>
              <Select
                open={selectServiceNow || openServiceNow}
                value={selectedServiceName}
                onOpen={() => {
                  this.setState({ openServiceNow: true });
                }}
                onClose={() => {
                  this.setState({ openServiceNow: false });
                }}
                onChange={changeServiceName}
              >
                <MenuItem key={-1} value="All">
                  {' '}
                  All{' '}
                </MenuItem>
                {arrServiceNameForFiltering}
              </Select>
            </FormControl>
          )}
        </div>

        <IconButton onClick={logOut}>
          <img src={LogOutIcon} className={classes.logoutIcon} alt="Logout" />
        </IconButton>
      </div>
    );
  }
}

export default withStyles(styles)(TopBar);
