import React from 'react';

// Material UI Components
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import {
  Card as DefaultCard,
  CardActionArea,
  CardMedia,
  CardActions,
  Grid,
  TextField,
  InputLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  Dialog,
  DialogContent,
  Snackbar,
  IconButton,
  Checkbox,
  Typography,
  Box,
  Tooltip,
} from '@material-ui/core';

// Icons
import CloseIcon from '@material-ui/icons/Close';

// Packages
import Select from 'react-select';

const styles = (theme) => ({
  styledSelect: {
    minWidth: '150px',
  },

  // Background color of cards
  actionCardsGreen: {
    backgroundColor: 'lightgreen',
  },
  actionCardsWhite: {
    backgroundColor: 'white',
  },

  // Radiogroups
  styledRadioGroup: {
    display: 'block',
    paddingTop: '30px',
    marginBottom: '-40px',
  },

  // Input for weight, station and menu item
  styledCardHeaders: {
    marginLeft: '30px',
    marginTop: '30px',
  },

  // Don't tag checkbox
  styledCardHeaderPicker: {
    marginLeft: '10px',
    marginTop: '10px',
    marginBottom: '10px',
  },

  // For slide show
  styledGrid: {
    marginTop: '0px',
    marginBottom: '0px',
    paddingTop: '50px',
  },

  // For sample images
  styledGridForSampleImages: {
    height: '100px',
  },

  // Weight text field
  styledTextField: {
    width: '85px',
  },
});

const Card = styled(({ ...other }) => <DefaultCard {...other} />)``;

class CardContainer extends React.PureComponent {
  controller = new AbortController();
  signal = this.controller.signal;

  constructor(props) {
    super(props);
    this.state = {
      imageMaximized: false,
      snackbarsOpen: false,
      snackbarContent: '',
      isSlideshow: false,
      suggestedItemsArray: [],
      anchorEl: null,

      // Adding new menu item
      isAddNewItem: false,
      costToBeAdded: '',
      stationToBeAdded: '',
      itemToBeAdded: '',
    };
  }

  componentWillUnmount = () => {
    this.controller.abort();
  };

  /**
   * Change station for the waste card. When the station is updated, arrMenuItem of the waste card is also updated.
   */
  changeStationDropDown = (stationForSelection) => {
    const { wasteCardIndex, changeWasteParameterValue } = this.props;
    let selectedStation = '';
    if (stationForSelection.value !== 'All') {
      selectedStation = stationForSelection.value;
    }
    // Update station value, and reset 'Menu Item' dropdown
    changeWasteParameterValue([
      this.createUpdateObject('station', selectedStation, wasteCardIndex),
      this.createUpdateObject('arrMenuItem', [], wasteCardIndex),
    ]);
  };

  /**
   * Change menu item for the waste card. When the menu item is updated, station of the waste card is also updated.
   */
  changeMenuItemDropDown = (selectedMenuItemForSelection, wasteThrownTime) => {
    const { wasteCardIndex, changeWasteParameterValue, arrMenuItem } = this.props;

    if (selectedMenuItemForSelection.value === 'All') {
      changeWasteParameterValue([
        this.createUpdateObject('arrMenuItem', [], wasteCardIndex),
        this.createUpdateObject('station', '', wasteCardIndex),
      ]);
    } else {
      // arrMenuItem contains all the menu items valid within the selected date range for the service the waste belong to.
      // Find all menu items with the same name as the selected menuItemName. Then filter out those with expiryDate before the waste's thrown date.
      const arrPossiblySelectedMenuItem = arrMenuItem.filter((menuItem) => {
        if (menuItem.name === selectedMenuItemForSelection.value) {
          if (
            menuItem.expiryDate === null ||
            new Date(menuItem.expiryDate) > new Date(wasteThrownTime)
          ) {
            return menuItem;
          }
        }
        return null;
      });
      // If there are more than 1 menu item with the same name, sort them accordingly to the expiryDate. The first menu item will have the expiryDate
      // beyond but closest to the waste's thrown date, so that will be the selected menu item.
      if (arrPossiblySelectedMenuItem.length > 1) {
        arrPossiblySelectedMenuItem.sort((menuItemA, menuItemB) => {
          if (menuItemA.expiryDate < menuItemB.expiryDate) {
            return 0;
          }
          return 1;
        });
      }
      const selectedMenuItem = arrPossiblySelectedMenuItem[0];

      changeWasteParameterValue([
        this.createUpdateObject('arrMenuItem', [selectedMenuItem], wasteCardIndex),
        this.createUpdateObject('station', selectedMenuItem.station, wasteCardIndex),
      ]);
    }
  };

  expandImage = () => {
    this.setState({
      imageMaximized: true,
    });
  };

  closeExpandedImage = () => {
    this.setState({
      imageMaximized: false,
    });
  };

  closeSnackbar = () => {
    this.setState({
      snackbarsOpen: false,
    });
  };

  // Make a menu item object in the form { value: menuItemName, label: menuItemName }
  createMenuItemForSelection = (waste) => {
    const value =
      waste.station === '' || waste.arrMenuItem.length === 0 ? 'All' : waste.arrMenuItem[0].name;
    const label = value === 'All' ? 'All Menu Items' : value;
    return {
      value,
      label,
    };
  };

  // Make a station object in the form { value: station, label: station }
  createStationForSelection = (station) => {
    const value = station === '' ? 'All' : station;
    const label = value === 'All' ? 'All Stations' : value;
    return {
      value,
      label,
    };
  };

  changeStationAndMenuItemToSuggestion = (event, wasteThrownTime) => {
    const value = event.target.value;
    const selectedMenuItemForSelection = {
      value,
      label: value,
    };
    this.changeMenuItemDropDown(selectedMenuItemForSelection, wasteThrownTime);
  };

  // Make an update object to be passed up to perform waste parameter value updates
  createUpdateObject = (parameterName, parameterValue, wasteCardIndex) => ({
    parameterName,
    parameterValue,
    wasteCardIndex,
  });

  getArrMenuItemForSelection = (selectedStation) => {
    const { arrStationMenuItemForSelection } = this.props;
    let station = selectedStation;
    if (!station) {
      station = 'All';
    }
    const foundStationMenuItemForSelection = arrStationMenuItemForSelection.find(
      (stationMenuItemForSelection) => stationMenuItemForSelection.station === station
    );
    return foundStationMenuItemForSelection.arrMenuItemForSelection;
  };

  render() {
    const {
      waste,
      changeWasteParameterValue,
      wasteCardIndex,
      arrStationForSelection,
      arrStationMenuItemForSelection,
      classes,
      pageType,
    } = this.props;
    const { imageMaximized, snackbarsOpen, snackbarContent } = this.state;
    // Note: arrSuggestion is currently hardcored. To be futher enhanced in future.
    // Might cause issue if 'Test' and 'Misc' are not in the menu items. But Adriel accepts for now.
    // ToDo: Write code to ensure that 'Test' and 'Misc' are always in the menu when creating new menu.
    const arrSuggestion = ['Test', 'Misc'];

    return (
      <React.Fragment>
        <Card>
          <CardActionArea>
            {/* Show miscellanous details of the waste when hovering over image */}
            <Tooltip
              title={
                <Box style={{ padding: '10px' }}>
                  <Typography>Location: {waste.locationName}</Typography>

                  <Typography>Service: {waste.serviceName}</Typography>

                  <Typography>Service ID: {waste.serviceId}</Typography>

                  <Typography>
                    Throw Time: {new Date(waste.time).toLocaleString('en-SG')}
                  </Typography>

                  <Typography>Bin Serial: {waste.serialNumber}</Typography>

                  <Typography>Waste ID: {waste.wasteId}</Typography>

                  <Typography>Image ID: {waste.imageId}</Typography>

                  <Typography>RFID Tag: {waste.inputTag}</Typography>
                </Box>
              }
            >
              <CardMedia
                component="img"
                classes={{ height: '50%', width: '10' }}
                image={waste.imageURL}
                onClick={this.expandImage}
              />
            </Tooltip>
          </CardActionArea>
          <CardActions
            tagged={waste.arrMenuItem.length !== 0 && !waste.doNotTag ? 'true' : 'false'}
            pagetype={pageType}
            className={
              waste.arrMenuItem.length !== 0 && !waste.doNotTag && pageType === 'UNTAGGED'
                ? classes.actionCardsGreen
                : classes.actionCardsWhite
            }
          >
            <Grid container direction="column" justify="center" alignItems="flex-start">
              <Grid>
                <div className={classes.styledCardHeaderPicker}>
                  <RadioGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={waste.doNotTag}
                          onChange={() =>
                            changeWasteParameterValue([
                              this.createUpdateObject('doNotTag', !waste.doNotTag, wasteCardIndex),
                            ])
                          }
                          color="primary"
                        />
                      }
                      label="Don't Tag"
                    />
                  </RadioGroup>
                </div>
              </Grid>

              <Grid
                item
                xs={12}
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                spacing={3}
              >
                {/* Weight, station and menu item fields */}
                <div className={classes.styledCardHeaders}>
                  <InputLabel>Weight (g)</InputLabel>
                  <TextField
                    className={classes.styledTextField}
                    value={waste.weight}
                    onChange={(event) =>
                      changeWasteParameterValue([
                        this.createUpdateObject('weight', event.target.value, wasteCardIndex),
                      ])
                    }
                  />
                </div>
                <div className={classes.styledCardHeaders}>
                  <InputLabel>Station</InputLabel>
                  <Select
                    className={classes.styledSelect}
                    value={this.createStationForSelection(waste.station)}
                    options={arrStationForSelection}
                    onChange={this.changeStationDropDown}
                    isSearchable={true}
                    menuPosition={'fixed'}
                    maxMenuHeight={250}
                  />
                </div>
                {arrStationMenuItemForSelection && waste.arrMenuItem && (
                  <div className={classes.styledCardHeaders}>
                    <InputLabel>Menu Item</InputLabel>
                    <Select
                      className={classes.styledSelect}
                      value={this.createMenuItemForSelection(waste)}
                      options={this.getArrMenuItemForSelection(waste.station)}
                      onChange={(value) => this.changeMenuItemDropDown(value, waste.time)}
                      isSearchable={true}
                      menuPosition={'fixed'}
                      maxMenuHeight={250}
                    />
                  </div>
                )}
              </Grid>

              {/* Suggestions for tagging from computer vision */}
              <Grid className={classes.styledGridForSampleImages} direction="column">
                <RadioGroup className={classes.styledRadioGroup}>
                  {arrSuggestion.map((suggestion, index) => (
                    <FormControlLabel
                      key={`${index}_${suggestion}`}
                      name={suggestion}
                      value={suggestion}
                      control={<Radio />}
                      label={suggestion}
                      onChange={(event) =>
                        this.changeStationAndMenuItemToSuggestion(event, waste.time)
                      }
                    />
                  ))}
                </RadioGroup>
              </Grid>
            </Grid>
          </CardActions>
        </Card>

        <Dialog
          fullWidth={false}
          maxWidth="xl"
          open={imageMaximized}
          scroll="body"
          onClose={this.closeExpandedImage}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogContent>
            <img src={waste.imageURL} alt="" />
          </DialogContent>
        </Dialog>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={snackbarsOpen}
          autoHideDuration={6000}
          onClose={this.closeSnackbar}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{snackbarContent}</span>}
          action={[
            <IconButton key="close" aria-label="close" color="inherit" onClick={this.closeSnackbar}>
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(CardContainer);
