import React from 'react';

// Material UI Components
import { withStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';

// Packages
import Pagination from 'react-mui-pagination';
import { DateRangePicker } from 'react-date-range';
import { enGB } from 'react-date-range/dist/locale/index.js';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

// Images
import dateRangeLogo from '../images/DateRangeIcon.svg';

const styles = (theme) => ({
  container: {
    marginBottom: '0px',
    position: 'sticky',
    top: 0,
    display: 'flex',
    padding: '1px 20px 1px 20px',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflow: 'visible',
    zIndex: 2,
    backgroundColor: '#e4e8e2',
  },

  // Number of images
  imgCounter: {
    fontSize: '20px',
    fontWeight: 'bolder',
  },

  // Date display
  selectDateBtn: {
    position: 'absolute',
    right: 0,
    marginRight: '20px',
    color: 'grey',
    border: '1px solid grey',
  },
  calendarIcon: {
    width: '24px',
    height: '24px',
    paddingRight: '8px',
  },

  // Calendar modal
  datePickContainer: {
    position: 'absolute',
    top: '75px',
    backgroundColor: '#d9ddda',
    padding: '20px',
    paddingBottom: '45px',
    borderRadius: '7px',
  },
  closeBtn: {
    cursor: 'pointer',
    position: 'absolute',
    display: 'block',
    padding: '2px 5px',
    lineHeight: '20px',
    right: '3px',
    top: '3px',
    fontSize: '28px',
  },

  // Pagination and submit btn container
  rightSideContainer: {
    display: 'flex',
  },
});

class ActionBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isChooseDate: false,
      focusedRange: [0, 0],
      dateRangePicker: {
        selection: {
          startDate: this.props.startDateTime,
          endDate: this.props.endDateTime,
          color: '#a6c49f',
          key: 'selection',
        },
      },
    };
  }
  changeIsChooseDate = () => {
    this.setState({
      isChooseDate: !this.state.isChooseDate,
    });
  };
  changeDateRangeInCalendar = (which, payload) => {
    const { changeDateRange } = this.props;
    this.setState({
      [which]: {
        ...this.state[which],
        ...payload,
      },
    });
    // new Date (selected date) so that all datetime has the same UTC format
    changeDateRange(new Date(payload.selection.startDate), new Date(payload.selection.endDate));
  };
  render() {
    const {
      title,
      numberOfAllWaste,
      numberOfAllImages,
      changePageNumber,
      submitUpdatedWasteForCurrentPage,
      currentPageNumber,
      perPage,
      startDateTime,
      endDateTime,
      resetDateRangeFilter,
      classes,
    } = this.props;

    const parseDateToDDMonYYYYFormat = (datetime) => {
      let currentDateTime = new Date(datetime);
      return currentDateTime
        .toLocaleDateString('en-ZA', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })
        .split(' ')
        .join(' ');
    };

    const { isChooseDate, dateRangePicker, focusedRange } = this.state;

    return (
      <div className={classes.container}>
        <Typography className={classes.imgCounter}>
          {numberOfAllImages} {title}
        </Typography>
        <Button variant="outlined" onClick={this.changeIsChooseDate}>
          <img className={classes.calendarIcon} src={dateRangeLogo} alt="Calendar" />
          {parseDateToDDMonYYYYFormat(startDateTime) +
            ' - ' +
            parseDateToDDMonYYYYFormat(endDateTime)}
        </Button>
        <Button variant="outlined" onClick={resetDateRangeFilter}>
          Reset Date Filter
        </Button>
        {isChooseDate && (
          <div className={classes.datePickContainer}>
            <span onClick={this.changeIsChooseDate} className={classes.closeBtn}>
              &times;
            </span>
            <div>
              <DateRangePicker
                onChange={this.changeDateRangeInCalendar.bind(this, 'dateRangePicker')}
                months={1}
                In
                locale={enGB}
                shownDate={startDateTime}
                ranges={[dateRangePicker.selection]}
                direction="horizontal"
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                minDate={new Date('1900-01-01T00:00:00')}
                maxDate={new Date()}
                focusedRange={focusedRange}
                onRangeFocusChange={(focusedRange) => {
                  const [, rangeStep] = focusedRange;
                  if (!rangeStep) {
                    this.changeIsChooseDate();
                  }
                  this.setState({ focusedRange });
                }}
              />
            </div>
            <br />
            <Button
              className={classes.selectDateBtn}
              variant="outlined"
              color="primary"
              onClick={() => {
                this.changeIsChooseDate();
              }}
            >
              Select Dates
            </Button>
          </div>
        )}

        <div className={classes.rightSideContainer}>
          <Pagination
            page={currentPageNumber}
            setPage={changePageNumber}
            perPage={perPage}
            total={numberOfAllWaste}
          />
          <Button variant="outlined" onClick={submitUpdatedWasteForCurrentPage}>
            Submit
          </Button>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ActionBar);
