import React from 'react';
import styled from 'styled-components';
import { Drawer, ListItem, Divider, ListItemText, ListItemIcon } from '@material-ui/core';

import menuIcon from '../images/menu.svg';
import remoteIcon from '../images/untagged.svg';
import taggedIcon from '../images/tagged.svg';
import GffLogo from '../images/GFFLogo.png';

const HeaderIcon = styled.img`
  width: 80%;
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const SideBarIcons = styled.img`
  width: 20px;
`;

const MyDrawer = styled(({ isMinimise, ...other }) => <Drawer {...other} />)`
  & .MuiDrawer-paperAnchorDockedLeft {
    width: 250px;
    background-color: #dee3db;
    border: none;
    z-index: 200;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  }
`;

export default class SideBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 1,
    };
  }

  handleListItemClick = (event, index) => {
    this.setState({
      selectedIndex: index,
    });
  };

  componentDidMount = () => {};

  render() {
    const { changeTabOnSideBar, changeShowDownloadPopup } = this.props;
    const { selectedIndex } = this.state;

    return (
      <MyDrawer color="#c1c1c1" variant="permanent" anchor="left" open>
        <HeaderIcon src={GffLogo} alt="Good For Food" />
        <Divider />

        <ListItem
          button
          selected={selectedIndex === 1}
          onClick={(event) => {
            changeTabOnSideBar('UNTAGGED');
            this.handleListItemClick(event, 1);
          }}
        >
          <ListItemIcon>
            <SideBarIcons src={remoteIcon} />
          </ListItemIcon>
          <ListItemText primary="Untagged" />
        </ListItem>

        <ListItem
          button
          selected={selectedIndex === 2}
          onClick={(event) => {
            changeTabOnSideBar('TAGGED');
            this.handleListItemClick(event, 2);
          }}
        >
          <ListItemIcon>
            <SideBarIcons src={taggedIcon} />
          </ListItemIcon>
          <ListItemText primary="Tagged" />
        </ListItem>

        <ListItem
          button
          selected={selectedIndex === 3}
          onClick={(event) => {
            changeTabOnSideBar('MENU');
            this.handleListItemClick(event, 3);
          }}
        >
          <ListItemIcon>
            <SideBarIcons src={menuIcon} />
          </ListItemIcon>
          <ListItemText primary="Menu" />
        </ListItem>

        <ListItem
          button
          onClick={(event) => {
            changeShowDownloadPopup();
          }}
        >
          <ListItemText primary="Download" />
        </ListItem>
      </MyDrawer>
    );
  }
}
