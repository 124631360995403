import React from 'react';

// Material UI Components
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Select, MenuItem, FormControl, InputLabel, Button } from '@material-ui/core';

// Date Package
import { enGB } from 'react-date-range/dist/locale/index.js';
import { DateRangePicker } from 'react-date-range';
import { addDays } from 'date-fns';

// Images
import dateRangeLogo from '../images/DateRangeIcon.svg';

const styles = (theme) => ({
  // Calendar
  dateContainer: {
    position: 'relative',
    top: '30px',
    backgroundColor: '#d9ddda',
    padding: '20px',
    borderRadius: '7px',
  },
  dateRangePicker: {
    width: '100%',
  },
  calendarIcon: {
    width: '24px',
    height: '24px',
    paddingRight: '8px',
  },
  closeBtn: {
    cursor: 'pointer',
    position: 'absolute',
    display: 'block',
    lineHeight: '20px',
    right: '2px',
    top: '2px',
    fontSize: '28px',
  },

  // Form
  formControl: {
    width: '28%',
    marginRight: '20px',
  },

  // Buttons
  cancelBtn: {
    width: '20%',
    backgroundColor: 'red',
    fontSize: '16px',
    color: 'white',
    padding: '5px 5px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  downloadBtn: {
    width: '20%',
    backgroundColor: 'green',
    fontSize: '16px',
    color: 'white',
    padding: '5px 5px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
});

const token = 'Bearer ' + sessionStorage.getItem('token');

class DownloadPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: parseInt(sessionStorage.getItem('userId')),
      chartName: '',
      endDateTime: new Date(),
      startDateTime: addDays(new Date(), -30),
      shouldOpenDataRangeModal: false,
      isDownloadTaggedImages: true,
      focusedRange: [0, 0],
      dateRangePicker: {
        selection: {
          startDate: addDays(new Date(), -30),
          endDate: new Date(),
          color: '#a6c49f',
          key: 'selection',
        },
      },
      arrLocationServiceForFiltering: [],
      arrMenuItemForFiltering: [],
      arrRestaurantServiceForFiltering: [],
      arrServiceForFiltering: [],
      arrStationForFiltering: [],
      selectedCompanyName: '',
      selectedRestaurantName: '',
      selectedLocationName: '',
      selectedServiceName: '',
      selectedServiceCompany: null,
      selectedStationName: '',
      selectedMenuItemName: '',
    };
  }

  openDateRangeModal = () => {
    this.setState({
      shouldOpenDataRangeModal: !this.state.shouldOpenDataRangeModal,
    });
  };

  changeDateRange = (which, payload) => {
    this.setState({
      [which]: {
        ...this.state[which],
        ...payload,
      },
      startDateTime: new Date(payload.selection.startDate),
      endDateTime: new Date(payload.selection.endDate),
    });
  };

  changeImageTypeToBeDownloaded = () => {
    this.setState({
      isDownloadTaggedImages: !this.state.isDownloadTaggedImages,
    });
  };

  /**
   * Fetch
   * - all stations for selected restaurant, location and service
   * - all menu items for selected restaurant, location and service
   */
  fetchArrServiceIdStationAndArrServiceIdStationMenuItemForSelection = async () => {
    try {
      const { userId, selectedServiceCompany, startDateTime, endDateTime } = this.state;
      const startDate = this.getDateFromDateTime(startDateTime);
      const endDate = this.getDateFromDateTime(endDateTime);
      const response = await fetch(
        '/api/fetch-arr-service-id-station-and-arr-service-id-station-menu-item-for-selection',
        {
          signal: this.signal,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
          body: JSON.stringify({
            userId,
            arrServiceCompany: [selectedServiceCompany],
            startDate,
            endDate,
          }),
        }
      );
      const responseJson = await response.json();
      // Note that arrStationForFiltering is an array of { value: station, label: station } while arrStationMenuItemForFiltering is an
      // array of { station, arrMenuItemForSelection } where arrMenuItemForSelection is an array of { value: menuItemId, label: menuItemName }
      this.setState({
        arrStationForFiltering:
          responseJson.arrServiceIdStationForSelection[1].arrStationForSelection,
        arrStationMenuItemForFiltering:
          responseJson.arrServiceIdStationMenuItemForSelection[1].arrStationMenuItemForSelection,
      });
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error(error);
      }
    }
  };

  /**
   * Get date in YYYY-MM-DD format from datetime
   */
  getDateFromDateTime = (datetime) => {
    return datetime.toLocaleDateString('fr-CA', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });
  };

  submit = async () => {
    try {
      // ToDo: Add the downloading (maybe zipfile format). The downloading is not working currently.
      const {
        selectedServiceCompany,
        selectedMenuItemName,
        selectedStationName,
        startDateTime,
        endDateTime,
        isDownloadTaggedImages,
        userId,
      } = this.state;

      const startDate = this.getDateFromDateTime(startDateTime);
      const endDate = this.getDateFromDateTime(endDateTime);
      const response = await fetch('/api/fetch-image-of-waste-for-download', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({
          userId,
          serviceCompany: selectedServiceCompany,
          selectedStation: selectedStationName,
          selectedMenuItemName,
          startDate,
          endDate,
          isTagged: isDownloadTaggedImages,
        }),
      });
      const responseJson = await response.json();
      const { arrImageURL } = responseJson;
      if (arrImageURL.length === 0) {
        alert('No waste found for the selected parameters');
      } else {
        arrImageURL.forEach((imageURL, index) => {
          if (index < 100) {
            setTimeout(() => {
              window.location.href = imageURL;
            }, index * 1000);
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  changeCompanyName = (event) => {
    const { arrCompanyService } = this.props;
    const { value } = event.target;

    const foundCompanyService = arrCompanyService.find(
      (companyService) => companyService.name === value
    );
    // Consolidating serviceCompany object to find stations and menu items if tagged images are to be downloaded
    const selectedCompany = {
      companyName: foundCompanyService.name,
      companyId: foundCompanyService.companyId,
      isAirline: foundCompanyService.isAirline,
    };
    this.setState({
      arrRestaurantServiceForFiltering: foundCompanyService.arrRestaurantService,
      selectedCompanyName: value,
      selectedRestaurantName: '',
      selectedLocationName: '',
      selectedServiceName: '',
      selectedStationName: '',
      selectedMenuItemName: '',
      selectedServiceCompany: selectedCompany,
    });
  };

  changeRestaurantName = (event) => {
    const { arrRestaurantServiceForFiltering, selectedServiceCompany } = this.state;
    const { value } = event.target;

    const foundRestaurantService = arrRestaurantServiceForFiltering.find(
      (restaurantService) => restaurantService.name === value
    );
    // Consolidating serviceCompany object to find stations and menu items if tagged images are to be downloaded
    const selectedRestaurantCompany = {
      ...selectedServiceCompany,
      restaurantId: foundRestaurantService.restaurantId,
      restaurantName: foundRestaurantService.name,
      isValid: foundRestaurantService.isValid,
    };
    this.setState({
      arrLocationServiceForFiltering: foundRestaurantService.arrLocationService,
      selectedRestaurantName: value,
      selectedLocationName: '',
      selectedServiceName: '',
      selectedStationName: '',
      selectedMenuItemName: '',
      selectedServiceCompany: selectedRestaurantCompany,
    });
  };

  changeLocationName = (event) => {
    const { arrLocationServiceForFiltering, selectedServiceCompany } = this.state;
    const { value } = event.target;

    const foundLocationService = arrLocationServiceForFiltering.find(
      (locationService) => locationService.name === value
    );
    // Consolidating serviceCompany object to find stations and menu items if tagged images are to be downloaded
    const selectedLocationCompany = {
      ...selectedServiceCompany,
      locationId: foundLocationService.locationId,
      restaurantId: foundLocationService.restaurantId,
      locationName: foundLocationService.name,
    };
    this.setState({
      arrServiceForFiltering: foundLocationService.arrService,
      selectedLocationName: value,
      selectedServiceName: '',
      selectedStationName: '',
      selectedMenuItemName: '',
      selectedServiceCompany: selectedLocationCompany,
    });
  };

  changeServiceName = (event) => {
    const { arrServiceForFiltering, isDownloadTaggedImages, selectedServiceCompany } = this.state;
    const { value } = event.target;

    const foundService = arrServiceForFiltering.find((service) => service.name === value);
    // Consolidating serviceCompany object to find stations and menu items if tagged images are to be downloaded
    const updatedSelectedServiceCompany = {
      ...selectedServiceCompany,
      ...foundService,
    };
    // Replace name with serviceName to differentiate between companyName, restaurantName, locationName and serviceName
    delete updatedSelectedServiceCompany.name;
    updatedSelectedServiceCompany.serviceName = value;
    this.setState(
      {
        selectedServiceName: value,
        selectedServiceCompany: updatedSelectedServiceCompany,
        selectedStationName: '',
        selectedMenuItemName: '',
      },
      () => {
        // For downloading tagged images, there are station and menu item column to be selected
        if (isDownloadTaggedImages) {
          this.fetchArrServiceIdStationAndArrServiceIdStationMenuItemForSelection();
        }
      }
    );
  };

  changeStation = (event) => {
    const { arrStationMenuItemForFiltering } = this.state;
    const { value } = event.target;
    let arrMenuItemForFiltering = [];
    if (value === 'All') {
      arrMenuItemForFiltering = [{ value: 'All', label: 'All Menu Items' }];
    } else {
      const foundStationMenuItem = arrStationMenuItemForFiltering.find(
        (stationMenuItem) => stationMenuItem.station === value
      );
      arrMenuItemForFiltering = foundStationMenuItem.arrMenuItemForSelection;
    }
    this.setState({
      selectedStationName: value,
      arrMenuItemForFiltering,
      selectedMenuItemName: '',
    });
  };

  changeMenuItemName = (event) => {
    const { value } = event.target;

    this.setState({
      selectedMenuItemName: value,
    });
  };

  render() {
    const {
      startDateTime,
      endDateTime,
      shouldOpenDataRangeModal,
      isDownloadTaggedImages,
      dateRangePicker,
      focusedRange,
      arrLocationServiceForFiltering,
      arrMenuItemForFiltering,
      arrRestaurantServiceForFiltering,
      arrServiceForFiltering,
      arrStationForFiltering,
      selectedCompanyName,
      selectedLocationName,
      selectedMenuItemName,
      selectedRestaurantName,
      selectedServiceName,
      selectedStationName,
    } = this.state;
    const { showDownloadPopup, closeDownloadPopup, arrCompanyService, classes } = this.props;

    const arrCompanyNameForFiltering = arrCompanyService.map((companyService, index) => {
      return (
        <MenuItem key={index} value={companyService.name}>
          {companyService.name}
        </MenuItem>
      );
    });

    const arrRestaurantNameForFitering = arrRestaurantServiceForFiltering.map(
      (restaurantService, index) => {
        return (
          <MenuItem key={index} value={restaurantService.name}>
            {restaurantService.name}
          </MenuItem>
        );
      }
    );

    const arrLocationNameForFiltering = arrLocationServiceForFiltering.map(
      (locationService, index) => {
        return (
          <MenuItem key={index} value={locationService.name}>
            {locationService.name}
          </MenuItem>
        );
      }
    );

    const arrServiceNameForFiltering = arrServiceForFiltering.map((service, index) => {
      return (
        <MenuItem key={index} value={service.name}>
          {service.name}
        </MenuItem>
      );
    });

    const arrStationNameForFiltering = arrStationForFiltering.map((station, index) => {
      return (
        <MenuItem key={index} value={station.value}>
          {station.label}
        </MenuItem>
      );
    });

    const arrMenuItemNameForFiltering = arrMenuItemForFiltering.map((menuItem, index) => {
      return (
        <MenuItem key={index} value={menuItem.value}>
          {menuItem.label}
        </MenuItem>
      );
    });

    const parseDateToDDMonthFormat = (datetime) => {
      let currentDate = new Date(datetime);
      return currentDate
        .toLocaleDateString('en-ZA', {
          month: 'long',
          day: 'numeric',
        })
        .split(' ')
        .join(' ');
    };

    return (
      <Dialog open={showDownloadPopup}>
        <DialogTitle>
          Select desired download parameters for{' '}
          <b>{isDownloadTaggedImages ? 'Tagged' : 'Untagged'}</b> Images
        </DialogTitle>
        <Button variant="outlined" onClick={this.changeImageTypeToBeDownloaded}>
          {isDownloadTaggedImages
            ? 'Click to download Untagged Images instead'
            : 'Click to download Tagged Images instead'}
        </Button>
        <DialogContent>
          <FormControl className={classes.formControl}>
            <InputLabel>Company</InputLabel>
            <Select
              value={selectedCompanyName}
              name="selectedCompanyName"
              onChange={this.changeCompanyName}
            >
              {arrCompanyNameForFiltering}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel>Restaurant</InputLabel>
            <Select
              value={selectedRestaurantName}
              name="selectedRestaurantName"
              onChange={this.changeRestaurantName}
            >
              {arrRestaurantNameForFitering}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel>Location</InputLabel>
            <Select
              value={selectedLocationName}
              name="selectedLocationName"
              onChange={this.changeLocationName}
            >
              {arrLocationNameForFiltering}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel>Service</InputLabel>
            <Select
              value={selectedServiceName}
              name="selectedServiceName"
              onChange={this.changeServiceName}
            >
              {arrServiceNameForFiltering}
            </Select>
          </FormControl>
          {isDownloadTaggedImages && (
            <>
              <FormControl className={classes.formControl}>
                <InputLabel>Station</InputLabel>
                <Select
                  value={selectedStationName}
                  name="selectedStationName"
                  onChange={this.changeStation}
                >
                  {arrStationNameForFiltering}
                </Select>
              </FormControl>

              <FormControl className={classes.formControl}>
                <InputLabel>Menu Item</InputLabel>
                <Select
                  value={selectedMenuItemName}
                  name="selectedMenuItemName"
                  onChange={this.changeMenuItemName}
                >
                  {arrMenuItemNameForFiltering}
                </Select>
              </FormControl>
            </>
          )}
        </DialogContent>
        <DialogContent>
          <Button variant="outlined" onClick={this.openDateRangeModal}>
            <img src={dateRangeLogo} className={classes.calendarIcon} alt="Calendar" />
            {parseDateToDDMonthFormat(startDateTime)} {'  -  '}
            {parseDateToDDMonthFormat(endDateTime)}
          </Button>
          {shouldOpenDataRangeModal && (
            <div className={classes.dateContainer}>
              <span onClick={this.openDateRangeModal} className={classes.closeBtn}>
                &times;
              </span>

              <div>
                <DateRangePicker
                  className={classes.dateRangePicker}
                  onChange={this.changeDateRange.bind(this, 'dateRangePicker')}
                  months={1}
                  locale={enGB}
                  shownDate={new Date(startDateTime)}
                  ranges={[dateRangePicker.selection]}
                  direction="horizontal"
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  minDate={new Date('1900-01-01T00:00:00')}
                  maxDate={new Date()}
                  focusedRange={focusedRange}
                  onRangeFocusChange={(focusedRange) => {
                    const [, rangeStep] = focusedRange;
                    if (!rangeStep) {
                      this.openDateRangeModal();
                    }
                    this.setState({ focusedRange });
                  }}
                />
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            className={classes.cancelBtn}
            onClick={() => {
              closeDownloadPopup();
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className={classes.downloadBtn}
            onClick={() => {
              this.submit();
            }}
          >
            Download
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(DownloadPopup);
