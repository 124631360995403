import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import LoginPage from './Authentication/LoginPage';
import Home from './Home.js';
export default class App extends React.Component {
  render() {
    return (
      <Router>
        <Route exact path="/" component={LoginPage} />
        <Route exact path="/home" component={Home} />
      </Router>
    );
  }
}
