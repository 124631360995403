import React from 'react';
import styled from 'styled-components';
import 'typeface-roboto';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import GFFLogo from '../images/GFFLogo.png';
import jwt_decode from 'jwt-decode';

const Logo = styled.img`
  width: 68%;
  height: 4;
`;

const MainLogin = styled.div`
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FormFields = styled.form`
  width: 100%; // Fix IE 11 issue.
  margin-top: 8px;
`;

const StyledButton = styled(Button)`
  && {
    margin: 24px 0px 16px;
  }
`;

const StyledBox = styled(Box)`
  && {
    margin-top: 40px;
  }
`;

const WarnText = styled.p`
  align-text: centre;
  color: #ff0000;
  font-size: 10px;
  font-weight: bold;
`;

const token = 'Bearer ' + sessionStorage.getItem('token');

export default class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: parseInt(sessionStorage.getItem('userId')),
      email: '',
      password: '',
      isLogin: true,
    };
  }

  componentDidMount = () => {
    this.checkSessionValidity();
  };

  checkSessionValidity = async () => {
    try {
      const { userId } = this.state;
      if (sessionStorage.getItem('token')) {
        const response = await fetch('/api/validate-session', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
          body: JSON.stringify({
            userId,
          }),
        });
        const responseStatus = response.status;
        if (responseStatus === 200) {
          const decoded = jwt_decode(sessionStorage.getItem('token'));
          this.loadHomePageForSuccessfulLogin(decoded);
        } else {
          alert('Response status is neither 404 (not found) nor 200 (success)');
        }
      }
    } catch (error) {
      console.log(`Caught promise: ${error}`);
    }
  };

  loadHomePageForSuccessfulLogin = (decoded) => {
    const { history } = this.props;
    const { email, companyName } = decoded;
    this.setState({
      isLogin: true,
      email,
    });

    history.push({
      pathname: '/home',
      state: { email, companyName },
    });
  };

  submit = async (event) => {
    try {
      const { email, password } = this.state;
      event.preventDefault();

      const response = await fetch('/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          password,
        }),
      });
      const responseStatus = response.status;
      const responseJson = await response.json();
      if (responseStatus === 200) {
        const decoded = jwt_decode(responseJson.token);
        sessionStorage.setItem('token', responseJson.token);
        sessionStorage.setItem('userId', responseJson.userId);
        sessionStorage.setItem('arrCompanyService', JSON.stringify(responseJson.arrCompanyService));
        this.loadHomePageForSuccessfulLogin(decoded);
      } else if (responseStatus === 404) {
        alert('Invalid email/password');
      } else {
        alert('Unknown Error. Please alert the admin.');
      }
      this.setState({
        password: '',
      });
    } catch (error) {
      console.log(`Caught promise: ${error}`);
    }
  };

  changeEmail = (event) => {
    const { value } = event.target;
    this.setState({
      email: value,
    });
  };

  changePassword = (event) => {
    const { value } = event.target;
    this.setState({
      password: value,
    });
  };

  isFormValid = () => {
    const { email, password } = this.state;
    return email.length > 0 && password.length > 0;
  };

  render() {
    const LoginPageFields = (
      <FormFields onSubmit={this.submit}>
        <TextField
          type="email"
          onChange={this.changeEmail}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Email Address"
          autoComplete="email"
          autoFocus
        />
        <TextField
          type="password"
          onChange={this.changePassword}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Password"
          autoComplete="current-password"
        />
        <StyledButton
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={!this.isFormValid()}
        >
          Sign In
        </StyledButton>
      </FormFields>
    );

    const MadeWithLove = (
      <Typography variant="body2" color="textSecondary" align="center">
        Built with love by the{' '}
        <Link color="inherit" href="https://www.goodforfood.sg/">
          Good For Food
        </Link>{' '}
        team.
      </Typography>
    );

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <MainLogin>
          <Logo src={GFFLogo} alt="GoodForFood Logo" />
          <h1>Remote-Tagging Server</h1>
          <WarnText>Warning: Only Good For Food administrators should access this portal.</WarnText>
          {LoginPageFields}
        </MainLogin>
        <StyledBox>{MadeWithLove}</StyledBox>
      </Container>
    );
  }
}
