import React from 'react';

// Material UI components
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import MaterialTable from 'material-table';

// Packages
import Papa from 'papaparse';
import Select from 'react-select';
import { CSVLink as DefaultCSVLink } from 'react-csv';

// Images
import uploadIcon from '../images/upload.svg';
import SampleMenu from '../images/SampleMenu.JPG';

const styles = (theme) => ({
  // Entire page
  menuPage: {
    left: '200px',
    top: '70px',
  },

  // Upload menu
  sampleMenuIcon: {
    height: '100px',
  },
  uploadBtnContainer: {
    paddingTop: '10px',
  },
  uploadBtn: {
    position: 'relative',
    paddingTop: '10px',
    width: '20%',
    left: '40%',
  },
  uploadCard: {
    backgroundColor: '#FFFFFF',
    position: 'relative',
  },
  cardActions: { display: 'block' },
  uploadIcon: {
    paddingRight: '5px',
    height: '24px',
  },
  iconStyle: {
    height: '100px',
  },
  btnDiv: {
    paddingtTop: '10px',
  },
  csvLink: {
    textDecoration: 'none',
    color: 'black',
  },

  // Edit section
  actionGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  editGroup: {
    paddingTop: '10px',
    width: '50%',
    left: '25%',
    position: 'relative',
    textAlign: 'center',
  },
  closeBtn: {
    cursor: 'pointer',
    position: 'absolute',
    display: 'block',
    padding: '2px 5px',
    lineHeight: '20px',
    right: '3px',
    top: '3px',
    fontSize: '28px',
  },

  // Select
  selectMenu: {
    width: '35%',
    zIndex: '200',
    paddingLeft: '20px',
  },

  // Page body
  tableGroup: {
    paddingTop: '20px',
    paddingBottom: '20px',
    width: '100%',
    flexWrap: 'wrap',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
  },
  eachTable: {
    paddingTop: '10px',
    minWidth: '46%',
  },

  // Loader
  progressStyle: {
    position: 'relative',
    left: '50%',
  },
});

const token = 'Bearer ' + sessionStorage.getItem('token');
class Menu extends React.Component {
  controller = new AbortController();
  signal = this.controller.signal;

  constructor(props) {
    super(props);
    this.state = {
      userId: parseInt(sessionStorage.getItem('userId')),
      arrMenuItem: [],
      csvFile: undefined,
      isCSVFileSelected: false,
      isUploadMenuItemActivated: false,
      showLoading: true,
      columns: [
        { title: 'Menu Item', field: 'name' },
        { title: 'Cost Per Kilogram', field: 'costPerKilogram' },
        // ToDo: Add edit button to edit the above fields
      ],
      arrStationForFiltering: [],
      arrSelectedStationForFiltering: [],
    };
  }

  componentDidMount = () => {
    const { isSpecificServiceSelected } = this.props;
    // Fetch arrMenuItem if there is a specific service already selected
    if (isSpecificServiceSelected) {
      this.fetchArrMenuItem();
    }
  };

  componentDidUpdate = (prevProps) => {
    const { isSpecificServiceSelected } = this.props;
    const currentSelectedServiceName = this.props.selectedServiceName;
    const previousSelectedServiceName = prevProps.selectedServiceName;
    if (isSpecificServiceSelected && currentSelectedServiceName !== previousSelectedServiceName) {
      this.setState({
        showLoading: true,
      });
      this.fetchArrMenuItem();
    }
  };

  componentWillUnmount = () => {
    this.controller.abort();
  };

  /**
   * Get the array of station(s) for filtering from arrMenuItem
   * @param {Object[]} arrMenuItem - Array of all menu item(s) of the selected service
   * @returns {Object[]} arrStationForFiltering - Array of station(s) of the selected service
   */
  getArrStationForFiltering = (arrMenuItem) => {
    // arrStationForFiltering does not require to consist station = 'All'
    const arrStationForFiltering = [];
    arrMenuItem.forEach((menuItem) => {
      const foundStation = arrStationForFiltering.find(
        (stationForFiltering) => stationForFiltering.value === menuItem.station
      );
      if (!foundStation) {
        arrStationForFiltering.push({
          value: menuItem.station,
          label: menuItem.station,
        });
      }
    });
    return arrStationForFiltering;
  };

  /**
   * Fetch the array of menu item(s) that belongs to the selected service
   */
  fetchArrMenuItem = async () => {
    try {
      const { arrServiceForFiltering, selectedServiceName } = this.props;
      const { userId } = this.state;
      const foundService = arrServiceForFiltering.find(
        (service) => service.name === selectedServiceName
      );
      const response = await fetch('/api/fetch-arr-menu-item-for-service', {
        signal: this.signal,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify({
          userId,
          menuId: foundService.menuId,
        }),
      });
      const responseJson = await response.json();
      const { arrMenuItem } = responseJson;
      const arrStationForFiltering = this.getArrStationForFiltering(arrMenuItem);
      this.setState({
        arrMenuItem,
        arrStationForFiltering,
        arrSelectedStationForFiltering: arrStationForFiltering,
        csvFile: undefined,
        isCSVFileSelected: false,
        isUploadMenuItemActivated: false,
        showLoading: false,
      });
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error(error);
      }
    }
  };

  activateUploadMenuItem = () => {
    this.setState({
      isUploadMenuItemActivated: !this.state.isUploadMenuItemActivated,
    });
  };

  changeUploadedCSVFile = (event) => {
    this.setState({
      csvFile: event.target.files[0],
      isCSVFileSelected: !this.state.isCSVFileSelected,
    });
  };

  /**
   * Add new menu item(s) to the selected service
   */
  uploadMenuItem = async (event) => {
    try {
      const { arrServiceForFiltering, selectedServiceName } = this.props;
      const { arrMenuItem, userId } = this.state;
      event.preventDefault();
      const { csvFile } = this.state;
      const results = await new Promise((resolve, reject) => {
        Papa.parse(csvFile, {
          dynamicTyping: true,
          header: true,
          skipEmptyLines: true,
          complete: (results) => resolve(results),
        });
      });
      if (results.data.length === 0) {
        throw new Error('EmptyFile');
      }
      const foundService = arrServiceForFiltering.find(
        (service) => service.name === selectedServiceName
      );
      const response = await fetch('/api/add-menu-item', {
        signal: this.signal,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify({
          userId,
          arrMenuItem,
          arrMenuItemFromCSVFile: results.data,
          menuId: foundService.menuId,
        }),
      });
      const responseJson = await response.json();
      if (responseJson.error) {
        throw new Error(responseJson.error);
      } else {
        const arrStationForFiltering = this.getArrStationForFiltering(responseJson.arrMenuItem);
        this.setState({
          arrMenuItem: responseJson.arrMenuItem,
          arrStationForFiltering,
          arrSelectedStationForFiltering: arrStationForFiltering,
          isUploadMenuItemActivated: false, // To minimize the 'Upload New Item' modal
          isCSVFileSelected: false, // To remove the 'Upload' button when the 'Upload New Item' modal pops up
        });
      }
    } catch (error) {
      console.error(error);
      if (
        error.message === 'InvalidParameterError' ||
        error.message === 'MissingParameterError' ||
        error.message === 'NullParameterError'
      ) {
        window.alert(
          'Error when adding new menu item(s). Please check that \n 1. the column titles are correctly named \n 2. the values are valid and of correct data types.'
        );
      } else if (error.message === 'EmptyFile') {
        window.alert('Uploaded file is empty. Please make sure that there are menu items in it.');
      } else {
        window.alert('Unknown error when uploading new menu items. Please notify admin.');
      }
      // To remove the 'Upload' button
      this.setState({
        isCSVFileSelected: false,
      });
    }
  };

  scrollToBottom = () => {
    var div = document.getElementById('table');
    div.scrollTop = div.scrollHeight - div.clientHeight;
  };

  /**
   * Update the attributes of menu item(s). Currently the feature only updates expiry date.
   * This function is separated from expireMenuItem function so that it can be used by other functions
   * in future to update other attributes of the menu item.
   * ToDo: To add the feature to update other attributes of the menu item
   */
  updateMenuItem = async (arrMenuItem) => {
    try {
      const { userId } = this.state;
      await fetch('/api/update-menu-item', {
        signal: this.signal,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify({
          userId,
          arrMenuItem,
        }),
      });
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error(error);
      }
    }
  };

  expireMenuItem = async (arrMenuItemToBeExpired) => {
    const { arrMenuItem } = this.state;
    const { getArrStationForFiltering, updateMenuItem } = this;
    let isAllMenuItemToBeExpired = false;
    if (arrMenuItemToBeExpired.length === arrMenuItem.length) {
      isAllMenuItemToBeExpired = true;
    }

    const expiryDate = new Date().toISOString().slice(0, 10);
    const arrMenuItemWithTheUpdatedExpiryDate = arrMenuItemToBeExpired.map((menuItem) => {
      const menuItemToBeExpired = { ...menuItem };
      menuItemToBeExpired.expiryDate = expiryDate;
      return menuItemToBeExpired;
    });

    await updateMenuItem(arrMenuItemWithTheUpdatedExpiryDate);
    if (isAllMenuItemToBeExpired) {
      this.setState({
        arrMenuItem: [],
        arrStationForFiltering: [],
        arrSelectedStationForFiltering: [],
      });
    } else {
      const arrUpdatedMenuItem = [];
      arrMenuItem.forEach((menuItem) => {
        const foundExpiredMenuItem = arrMenuItemToBeExpired.find(
          (menuItemToBeExpired) => menuItemToBeExpired.menuItemId === menuItem.menuItemId
        );
        if (!foundExpiredMenuItem) {
          arrUpdatedMenuItem.push(menuItem);
        }
      });

      this.setState({
        arrMenuItem: arrUpdatedMenuItem,
        arrStationForFiltering: getArrStationForFiltering(arrUpdatedMenuItem),
      });
    }
  };

  changeStationForFiltering = (arrSelectedStationForFiltering) => {
    this.setState({
      arrSelectedStationForFiltering,
    });
  };

  render() {
    const { isSpecificServiceSelected, classes } = this.props;
    const {
      columns,
      isCSVFileSelected,
      isUploadMenuItemActivated,
      arrSelectedStationForFiltering,
      showLoading,
      arrMenuItem,
      arrStationForFiltering,
    } = this.state;
    const arrMenuItemInCSVFormat = Papa.unparse(
      arrMenuItem.map((menuItem) => {
        return {
          name: menuItem.name,
          costPerKilogram: menuItem.costPerKilogram,
          station: menuItem.station,
        };
      })
    );

    return (
      <div className={classes.menuPage}>
        {!isSpecificServiceSelected && (
          <h1>Please select a specific service ('All' is not accepted).</h1>
        )}
        {isSpecificServiceSelected && (
          <div>
            {!isUploadMenuItemActivated && (
              <div className={classes.btnDiv}>
                <Button
                  className={classes.uploadBtn}
                  variant="outlined"
                  onClick={this.activateUploadMenuItem}
                >
                  <img src={uploadIcon} className={classes.uploadIcon} alt="Upload" />
                  Upload new menu items
                </Button>
                <Button
                  className={classes.uploadBtn}
                  variant="outlined"
                  onClick={() => this.expireMenuItem(arrMenuItem)}
                >
                  Expire All
                </Button>
              </div>
            )}

            {isUploadMenuItemActivated && (
              <div className={classes.editGroup}>
                <Card className={classes.uploadCard}>
                  <span className={classes.closeBtn} onClick={this.activateUploadMenuItem}>
                    &times;
                  </span>
                  <CardContent>
                    <img src={SampleMenu} className={classes.iconStyle} alt="Sample Menu" />
                    <Typography variant="h5" component="h2">
                      Upload New Menu Items
                    </Typography>
                    <Typography variant="body2" component="p">
                      Please ensure your menu is in the correct format!
                    </Typography>
                  </CardContent>
                  <CardActions className={classes.cardActions}>
                    <input
                      type="file"
                      accept=".csv"
                      id="raised-button-file"
                      onChange={this.changeUploadedCSVFile}
                    />

                    {isCSVFileSelected && (
                      <label htmlFor="raised-button-file">
                        <Button
                          variant="outlined"
                          component="span"
                          raised
                          onClick={this.uploadMenuItem}
                        >
                          Upload
                        </Button>
                      </label>
                    )}
                  </CardActions>
                </Card>
              </div>
            )}

            {!showLoading && (
              <div>
                <div className={classes.actionGroup}>
                  <Select
                    className={classes.selectMenu}
                    value={arrSelectedStationForFiltering}
                    isMulti
                    onChange={this.changeStationForFiltering}
                    options={arrStationForFiltering}
                    isSearchable={true}
                  />
                  <div>
                    <Button variant="outlined">
                      <DefaultCSVLink className={classes.csvLink} data={arrMenuItemInCSVFormat}>
                        Export CSV
                      </DefaultCSVLink>
                    </Button>
                  </div>
                </div>
                <div className={classes.tableGroup}>
                  <link
                    rel="stylesheet"
                    href="https://fonts.googleapis.com/icon?family=Material+Icons"
                  />
                  {arrSelectedStationForFiltering.length !== 0 &&
                    arrSelectedStationForFiltering.map((selectedStationForFiltering, index) => (
                      <div className={classes.eachTable} key={index}>
                        <MaterialTable
                          id="table"
                          title={selectedStationForFiltering.value}
                          columns={columns}
                          data={arrMenuItem.filter(
                            (menuItem) => menuItem.station === selectedStationForFiltering.value
                          )}
                          options={{
                            exportButton: false,
                            paging: false,
                            pageSize: 1,
                            actionsColumnIndex: -1,
                            selection: true,
                          }}
                          actions={[
                            {
                              tooltip: 'Remove All Selected Items',
                              icon: 'delete',
                              onClick: (evt, selectedRows) =>
                                new Promise((resolve) => {
                                  setTimeout(() => {
                                    resolve();
                                    this.expireMenuItem(selectedRows);
                                  }, 600);
                                }),
                            },
                          ]}
                        />
                      </div>
                    ))}
                  {arrSelectedStationForFiltering.length === 0 && (
                    <h1>There is no menu item for this selected service.</h1>
                  )}
                </div>
              </div>
            )}

            {showLoading && <CircularProgress className={classes.progressStyle} />}
            <hr />
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(Menu);
