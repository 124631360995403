import {
  Button,
  Grid,
  IconButton,
  InputLabel,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import searchLogo from '../images/search.svg';
import ActionBar from '../Layout/ActionBar';
import CardContainer from '../Layout/Card';
import { AirlineCard } from '../Layout/AirlineCard';

const StyledSelect = styled(Select)`
  width: 20%;
`;

const StyledCircularProgress = styled(CircularProgress)`
  position: relative;
  left: 50%;
`;

const Container = styled.div`
  padding: 30px;
`;

const StyledCardHeaders = styled.div`
  margin-left: 30px;
  margin-top: 10px;
`;

const SearchLogo = styled.img`
  padding-top: 10px;
  padding-left: 10px;
`;

const FilterGroup = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 1fr 15fr;
`;

const token = 'Bearer ' + sessionStorage.getItem('token');
const searchStationDefault = { value: 'All', label: 'All Stations' };
const searchMenuItemDefault = { value: 'All', label: 'All Menu Items' };

/**
 * Function compares 2 date time to see if they have the same day, month and year
 * @param {dateTime} previousDateTime - Previous date time for comparison
 * @param {dateTime} currentDateTime - Current date time for comparsion
 * @returns {boolean} Boolean - Indicates if the two date time has the same day, month and year
 */
const compareDateByDayMonthYear = (previousDateTime, currentDateTime) => {
  const isDaySame = previousDateTime.getDate() === currentDateTime.getDate();
  const isMonthSame = previousDateTime.getMonth() === currentDateTime.getMonth();
  const isYearSame = previousDateTime.getFullYear() === currentDateTime.getFullYear();
  if (isDaySame && isMonthSame && isYearSame) {
    return true;
  }
  return false;
};
export default class Waste extends React.Component {
  controller = new AbortController();
  signal = this.controller.signal;

  constructor(props) {
    super(props);
    this.state = {
      userId: parseInt(sessionStorage.getItem('userId')),

      arrWaste: [], // All waste(s) fetched based on the selected services
      arrWasteForCurrentPage: [], // Waste(s) shown on the current page
      arrFilteredWasteByMenuItemAndStation: [], // All waste(s) fetched and filtered by station and/or menu item
      numberOfAllWaste: 0, // Number of data records fetched
      numberOfAllImages: 0, // Number of images fetched
      arrServiceIdStationForSelection: [], // All stations for selection for selected restaurant/location/service
      arrServiceIdStationMenuItemForSelection: [], // All menu items for selection for selected restaurant/location/service,
      isAirline: false, // Check for rendering card

      // UI
      showLoading: true,
      submitSnackbarOpen: false,

      // Selected date range
      startDateTime: new Date('2018-01-01'),
      endDateTime: new Date(),

      // Pagination
      currentPageNumber: 1,
      perPage: 24,

      // Variables for filtering
      showFilterBar: false,
      searchStation: searchStationDefault,
      searchMenuItem: searchMenuItemDefault,

      // Error state and message
      errorOccurred: false,
      errorMessage: '',

      // Service usage session
      showServicePrompt: false,
      servicePromptTimer: {},
    };
  }

  componentDidMount = () => {
    const { isServiceSelected, arrSelectedServiceCompany } = this.props;
    // Fetch waste if there is a service already selected. Service can be all or a specific service.
    if (isServiceSelected) {
      this.setState({
        isAirline: arrSelectedServiceCompany[0].isAirline,
      });
      this.fetchArrServiceIdStationAndArrServiceIdStationMenuItemForSelection();
      this.fetchWaste();
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const {
      arrSelectedServiceCompany,
      isServiceSelected,
      selectedCompanyName,
      selectedRestaurantName,
      selectedLocationName,
      selectedServiceName,
      pageType,
    } = this.props;
    const {
      startDateTime,
      endDateTime,
      searchStation,
      searchMenuItem,
      arrWaste,
      currentPageNumber,
    } = this.state;
    let shouldFetchWaste = false;
    // Fetch waste(s) if there is a change in the Company/Restaurant/Location/Service dropdown
    if (
      ((selectedCompanyName && selectedCompanyName !== prevProps.selectedCompanyName) ||
        (selectedRestaurantName && selectedRestaurantName !== prevProps.selectedRestaurantName) ||
        (selectedLocationName && selectedLocationName !== prevProps.selectedLocationName) ||
        (selectedServiceName && selectedServiceName !== prevProps.selectedServiceName)) &&
      isServiceSelected
    ) {
      this.setState({
        isAirline: arrSelectedServiceCompany[0].isAirline,
        startDateTime: new Date('2018-01-01'), // Reset date range
        endDateTime: new Date(),
      });
      this.fetchArrServiceIdStationAndArrServiceIdStationMenuItemForSelection();
      this.setEndSession(false);
      shouldFetchWaste = true;
    }
    // Fetch waste(s) if there is a change in date range
    else if (
      !compareDateByDayMonthYear(prevState.startDateTime, startDateTime) ||
      !compareDateByDayMonthYear(prevState.endDateTime, endDateTime)
    ) {
      shouldFetchWaste = true;
    }
    // Fetch waste(s) if page type was changed
    else if (pageType !== prevProps.pageType && selectedServiceName) {
      shouldFetchWaste = true;
    }
    // Fetch waste(s) if there is a change in filter options - filter by station and/or menu item
    else if (
      searchStation.value !== prevState.searchStation.value ||
      searchMenuItem.value !== prevState.searchMenuItem.value
    ) {
      const arrFilteredWasteByMenuItemAndStation = this.getWasteFilteredByMenuItemAndStation(
        arrWaste,
        searchStation,
        searchMenuItem
      );
      const arrWasteForCurrentPage = this.getWasteForCurrentPage(
        arrFilteredWasteByMenuItemAndStation,
        currentPageNumber,
        24
      );
      this.setState({
        arrFilteredWasteByMenuItemAndStation,
        arrWasteForCurrentPage,
        numberOfAllImages: arrFilteredWasteByMenuItemAndStation.length,
        numberOfAllWaste: arrFilteredWasteByMenuItemAndStation.length,
      });
    }

    if (shouldFetchWaste) {
      this.setState({
        showLoading: true,
        numberOfAllImages: 0,
        currentPageNumber: 1, // Reset to first page
        errorOccurred: false,
      });
      this.fetchWaste();
    }
  };

  componentWillUnmount = () => {
    this.controller.abort();
  };

  getWasteFilteredByMenuItemAndStation = (arrWaste, searchStation, searchMenuItem) => {
    let arrFilteredWaste = arrWaste;
    if (searchStation.value !== 'All') {
      arrFilteredWaste = arrWaste.filter((waste) => {
        const foundMenuItem = waste.arrMenuItem.find(
          (menuItem) => menuItem.station === searchStation.value
        );
        if (foundMenuItem) {
          return waste;
        }
        return null;
      });
    }

    if (searchMenuItem.value !== 'All') {
      arrFilteredWaste = arrFilteredWaste.filter((filteredWaste) => {
        const foundMenuItem = filteredWaste.arrMenuItem.find(
          (menuItem) => menuItem.name === searchMenuItem.value
        );
        if (foundMenuItem) {
          return filteredWaste;
        }
        return null;
      });
    }
    return arrFilteredWaste;
  };

  /**
   * Fetch all tagged waste(s) for selected company, restaurant, location and service,
   * as well as start/end date, station and item filters
   */
  fetchTaggedWaste = async () => {
    try {
      const { arrSelectedServiceCompany } = this.props;
      const {
        currentPageNumber,
        startDateTime,
        endDateTime,
        searchMenuItem,
        searchStation,
        userId,
      } = this.state;
      const startDate = this.getDateFromDateTime(startDateTime);
      const endDate = this.getDateFromDateTime(endDateTime);
      const response = await fetch('/api/fetch-waste-for-card', {
        signal: this.signal,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify({
          userId,
          arrServiceCompany: arrSelectedServiceCompany,
          startDate,
          endDate,
          isTagged: true,
        }),
      });
      const responseJson = await response.json();
      if (!response.ok) {
        this.categoriseError(response, responseJson);
      }

      // Update state
      const { arrWasteForCard } = responseJson;
      const arrFilteredWasteByMenuItemAndStation = this.getWasteFilteredByMenuItemAndStation(
        arrWasteForCard,
        searchMenuItem,
        searchStation
      );
      const arrWasteForCurrentPage = this.getWasteForCurrentPage(
        arrFilteredWasteByMenuItemAndStation,
        currentPageNumber,
        24
      );
      this.setState({
        arrFilteredWasteByMenuItemAndStation,
        arrWasteForCurrentPage,
        arrWaste: arrWasteForCard,
        numberOfAllWaste: arrWasteForCard.length,
        numberOfAllImages: arrWasteForCard.length,
        showLoading: false,
        perPage: 24,
      });
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error(error);
      }
    }
  };

  /**
   * Fetch all untagged waste(s) for selected company, restaurant, location and service,
   * as well as start/end date, station and item filters
   */
  fetchUntaggedWaste = async () => {
    try {
      const { arrSelectedServiceCompany } = this.props;
      const { currentPageNumber, startDateTime, endDateTime, userId } = this.state;
      const startDate = this.getDateFromDateTime(startDateTime);
      const endDate = this.getDateFromDateTime(endDateTime);
      const response = await fetch('/api/fetch-waste-for-card', {
        signal: this.signal,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify({
          userId,
          arrServiceCompany: arrSelectedServiceCompany,
          startDate,
          endDate,
          isTagged: false,
        }),
      });
      const responseJson = await response.json();
      if (!response.ok) {
        this.categoriseError(response, responseJson);
      }

      // Update state. Note that there is no filtering for untaggged page
      const { arrWasteForCard } = responseJson;
      const arrWasteForCurrentPage = this.getWasteForCurrentPage(
        arrWasteForCard,
        currentPageNumber,
        24
      );
      this.setState({
        arrFilteredWasteByMenuItemAndStation: arrWasteForCard,
        arrWaste: arrWasteForCard,
        arrWasteForCurrentPage,
        numberOfAllWaste: arrWasteForCard.length,
        numberOfAllImages: arrWasteForCard.length,
        showLoading: false,
        perPage: 24,
      });
      this.setTimer(); // timer to open up dialog to extend session for untagged waste
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error(error);
      }
    }
  };

  /**
   * Fetch all tagged / untagged waste(s) for selected company, restaurant, location and service,
   * as well as start/end date, station and item filters
   */
  fetchWaste = async () => {
    const { pageType } = this.props;
    if (pageType === 'TAGGED') {
      await this.fetchTaggedWaste();
    } else {
      await this.fetchUntaggedWaste();
    }
  };

  /**
   * Fetch
   * - all stations for selected restaurant, location and service
   * - all menu items for selected restaurant, location and service
   */
  fetchArrServiceIdStationAndArrServiceIdStationMenuItemForSelection = async () => {
    try {
      const { arrSelectedServiceCompany } = this.props;
      const { userId, startDateTime } = this.state;
      const startDate = this.getDateFromDateTime(startDateTime);
      const response = await fetch(
        '/api/fetch-arr-service-id-station-and-arr-service-id-station-menu-item-for-selection',
        {
          signal: this.signal,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
          body: JSON.stringify({
            userId,
            arrServiceCompany: arrSelectedServiceCompany,
            startDate,
          }),
        }
      );
      const responseJson = await response.json();
      this.setState({
        arrServiceIdStationForSelection: responseJson.arrServiceIdStationForSelection,
        arrServiceIdStationMenuItemForSelection:
          responseJson.arrServiceIdStationMenuItemForSelection,
      });
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error(error);
      }
    }
  };

  /**
   * Returns the list of waste that is being rendered on the current page.
   * Each waste is also given a 'doNotTag' and 'isArrMenuItemUpdated' property.
   */
  getWasteForCurrentPage = (arrWaste, currentPageNumber, perPage) => {
    const { pageType } = this.props;
    const { isAirline } = this.state;
    const startIndex = (currentPageNumber - 1) * perPage;
    const endIndex = currentPageNumber * perPage;
    // For non-airline waste
    if (!isAirline) {
      if (pageType === 'UNTAGGED') {
        return arrWaste.slice(startIndex, endIndex).map((waste) => ({
          ...waste,
          doNotTag: true,
          isArrMenuItemUpdated: false,
          station: '', // denotes no station selected
        }));
      }
      return arrWaste.slice(startIndex, endIndex).map((waste) => ({
        ...waste,
        doNotTag: true,
        isArrMenuItemUpdated: false,
        station: waste.arrMenuItem[0].station,
      }));
      // For airline waste
    } else {
      return arrWaste.slice(startIndex, endIndex).map((waste) => ({
        ...waste,
        doNotTag: true,
        isArrMenuItemUpdated: false,
      }));
    }
  };

  /**
   * Handle the changing of currently selected page
   */
  changePageNumber = (event, page) => {
    const { arrWaste, perPage } = this.state;
    const arrWasteForCurrentPage = this.getWasteForCurrentPage(arrWaste, page, perPage);
    this.setState({
      currentPageNumber: page,
      arrWasteForCurrentPage,
    });
  };

  submitUpdatedWasteForCurrentPage = async () => {
    try {
      const { arrWasteForCurrentPage, currentPageNumber, numberOfAllWaste, perPage, userId } =
        this.state;

      // Filter out waste(s) that has updated parameters
      const arrWasteToBeUpdated = [];
      arrWasteForCurrentPage.forEach((waste) => {
        if (!waste.doNotTag && waste.weight >= 0) {
          const wasteToBeUpdated = { ...waste };
          if (!wasteToBeUpdated.isArrMenuItemUpdated) {
            delete wasteToBeUpdated.arrMenuItem; // No menu item needs to be updated, hence removed
          }
          // Delete as these are attributes created for Frontend only
          delete wasteToBeUpdated.isArrMenuItemUpdated;
          delete wasteToBeUpdated.doNotTag;
          delete wasteToBeUpdated.station;
          arrWasteToBeUpdated.push(wasteToBeUpdated);
        }
      });
      // Check if arrWasteToBeUpdated is empty. If it is, an alert message will pop up. If not, the array will be passed to Backend
      // to update to database.
      const numberOfWasteToBeUpdated = arrWasteToBeUpdated.length;
      if (numberOfWasteToBeUpdated !== 0) {
        // Calculate which page to display after submitting
        const pageBeforeSubmit = currentPageNumber;
        const lastPageBeforeSubmit = Math.ceil(numberOfAllWaste / perPage);

        let pageAfterSubmit;
        // If user is on last page during submission, display second last page after submission only if
        // the number of waste to be updated is equal to the number of waste on the current page.
        if (
          pageBeforeSubmit === lastPageBeforeSubmit &&
          numberOfWasteToBeUpdated === arrWasteForCurrentPage.length
        ) {
          pageAfterSubmit = currentPageNumber - 1;
        } else {
          pageAfterSubmit = currentPageNumber;
        }

        await fetch('/api/update-waste', {
          signal: this.signal,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
          body: JSON.stringify({
            userId,
            arrWaste: arrWasteToBeUpdated,
          }),
        });

        this.setState({
          showLoading: true,
          submitSnackbarOpen: true,
          currentPageNumber: pageAfterSubmit,
        });
        this.fetchWaste();
      } else {
        alert(
          "No waste card is submitted! Please ensure the 'Dont Tag' is unchecked for waste card(s) to be submitted for update."
        );
      }
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error(error);
      }
    }
  };

  /**
   * Updates waste values stored in state, which is then passed into Cards for display.
   * Takes in an array of Update objects with properties: 'parameterName', 'parameterValue', WasteCardIndex'.
   * - parameterName: to differentiate which property was changed
   * - parameterValue: the value to change to
   * - wasteCardIndex: which of the card being displayed was changed
   * - Example of Update object: { parameterName: 'station', parameterValue: 'Western', wasteCardIndex: 3,  }
   */
  changeWasteParameterValue = (arrUpdatedObject) => {
    let arrUpdatedWasteForCurrentPage = this.state.arrWasteForCurrentPage;

    arrUpdatedObject.forEach((updatedObject) => {
      const { parameterName, parameterValue, wasteCardIndex } = updatedObject;

      // Update waste parameter immutably. Return the updated waste if the wasteCardIndex matches.
      arrUpdatedWasteForCurrentPage = arrUpdatedWasteForCurrentPage.map((waste, index) => {
        if (wasteCardIndex === index) {
          const updatedWaste = { ...waste };
          updatedWaste.doNotTag = false;
          if (parameterName === 'weight') {
            const alphabeticalCharacters = /[A-Za-z]/;
            if (
              parameterValue < 0 ||
              parameterValue > 15000 ||
              alphabeticalCharacters.test(parameterValue)
            ) {
              alert(
                'Weight must be in the range 0 - 15000 and must only contain numbers and/or a decimal place'
              );
              updatedWaste.doNotTag = true;
              updatedWaste.weight = 0;
            } else {
              updatedWaste.weight = Number(parameterValue);
            }
          } else if (parameterName === 'station') {
            updatedWaste.station = parameterValue;
          } else if (parameterName === 'suitability') {
            updatedWaste.trainingSuitability = parameterValue;
          } else if (parameterName === 'doNotTag') {
            updatedWaste.doNotTag = parameterValue;
          } else {
            updatedWaste.arrMenuItem = parameterValue;
            // To indicate if there is any change to arrMenuItem. If there isnt, arrMenuItem will be omitted when passing the updated waste to Backend
            updatedWaste.isArrMenuItemUpdated = true;
          }
          return updatedWaste;
        }
        return waste;
      });
    });
    this.setState({
      arrWasteForCurrentPage: arrUpdatedWasteForCurrentPage,
    });
  };

  closeSubmitSnackbar = () => {
    this.setState({
      submitSnackbarOpen: false,
    });
  };

  changeDateRange = (startDateTime, endDateTime) => {
    this.setState({
      startDateTime,
      endDateTime,
    });
  };

  resetDateRangeFilter = () => {
    this.setState({
      startDateTime: new Date('2018-01-01'),
      endDateTime: new Date(),
    });
  };

  resetFilterForStationAndMenuItem = () => {
    this.setState({
      searchMenuItem: searchMenuItemDefault,
      searchStation: searchStationDefault,
      showFilterBar: false,
    });
  };

  toggleFilterBar = () => {
    this.setState({ showFilterBar: !this.state.showFilterBar });
  };

  changeStationDropDownForFilterBar = (selectedStationForSelection) => {
    this.setState({
      searchStation: selectedStationForSelection,
      searchMenuItem: searchMenuItemDefault,
    });
  };

  changeMenuItemDropDownForFilterBar = (selectedMenuItemForSelection) => {
    const { arrServiceIdStationMenuItemForSelection } = this.state;

    // Retrieve and set the menu item's corresponding station
    arrServiceIdStationMenuItemForSelection.forEach((serviceIdStationMenuItemForSelection) => {
      // Ignore if serviceId = 'All'
      if (serviceIdStationMenuItemForSelection.serviceId === -1) {
        return;
      }
      serviceIdStationMenuItemForSelection.arrStationMenuItemForSelection.forEach(
        (stationMenuItemForSelection) => {
          // Ignore if station = 'All'
          if (stationMenuItemForSelection.station === 'All') {
            return;
          }
          // Find matching menu item value
          stationMenuItemForSelection.arrMenuItemForSelection.forEach((menuItemForSelection) => {
            if (menuItemForSelection.value === selectedMenuItemForSelection.value) {
              this.setState({
                searchMenuItem: selectedMenuItemForSelection,
                searchStation: {
                  value: stationMenuItemForSelection.station,
                  label: stationMenuItemForSelection.station,
                },
              });
              return;
            }
          });
        }
      );
    });
  };

  /**
   * Handles an error that occurs during fetch.
   * Returns the original response afterwards.
   */
  categoriseError = (response, data) => {
    if (data) {
      if (data.error === 'ServiceInUseError') {
        this.setState({
          errorOccurred: true,
          errorMessage:
            'Service selected is in use. Please choose another service or come back later.',
        });
      } else {
        this.setState({
          errorOccurred: true,
          errorMessage: data.message,
        });
      }
    } else {
      this.setState({
        errorOccurred: true,
        errorMessage: 'Failed to get data. Please check the network connection.',
      });
    }
  };

  closeServicePrompt = () => {
    this.setState({ showServicePrompt: false });
  };

  /**
   * Get date in YYYY-MM-DD format from datetime
   */
  getDateFromDateTime = (datetime) => {
    return new Date(datetime).toLocaleDateString('fr-CA', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });
  };

  openServicePrompt = () => {
    this.setState({ showServicePrompt: true });
    const timer = setTimeout(() => {
      this.setEndSession(true);
    }, 60 * 1000); // Set to 60 seconds
    this.setState({
      servicePromptTimer: timer,
    });
  };

  /**
   *  Method to set the timer for the service dialog to prompt the user
   */
  setTimer = () => {
    clearTimeout(this.state.servicePromptTimer);
    const timer = setTimeout(() => {
      this.openServicePrompt();
    }, 60 * 60 * 1000); // Set to 1 hour
    this.setState({
      servicePromptTimer: timer,
    });
  };

  /**
   * Method to extend the session for the service the user is in
   */
  setExtend = () => {
    this.setTimer();
    this.closeServicePrompt();
  };

  /**
   * Method to unlock the service that the user is in
   */
  // TODO: Unlock and locking service should be executed at the same time in the backend instead
  //       of separate calls
  // TODO: Unlocking services can possibly be based on user id instead of service id
  // TODO: The timer to unlock service should be implemented in the backend to ensure that the
  //       timer continues even after the user closes the window
  setEndSession = async (isEndCurrent) => {
    try {
      const { resetSelectedParameter } = this.props;
      // Note (5 Jul 23): Remove for now, since there is only one user and we are shifting to Vision-UI
      // let arrServiceCompany = [];
      // if (isEndCurrent) {
      //   arrServiceCompany = arrSelectedServiceCompany;
      // } else {
      //   arrServiceCompany = arrPrevSelectedServiceCompany;
      // }
      // await fetch('/api/unlock-service', {
      //   signal: this.signal,
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //     Authorization: 'Bearer ' + token,
      //   },
      //   body: JSON.stringify({
      //     userId,
      //     arrServiceCompany,
      //   }),
      // });

      if (isEndCurrent) {
        resetSelectedParameter(); // To reset the selected company, restaurant, location and service to reload the page
        this.closeServicePrompt();
      }
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Using serviceId,
   * 1. Get an array of stations from arrServiceIdStationForSelection to filter by at the filter bar or selection in the individual waste card or,
   * 2. Get an array of stationMenuItems from arrServiceIdStationMenuItemForSelection for selection in the individual waste card
   * 3. Get an array of menu items from arrServiceIdStationMenuItemForSelection for waste update
   *
   * Note: serviceId = -1 means 'All'
   */
  getArrayByServiceId = (array, subArrayKey, serviceId) => {
    const foundObject = array.find((object) => object.serviceId === serviceId);
    return foundObject[subArrayKey];
  };

  /**
   * Get an array of menu items to filter by at the filter bar, depending selected station
   */
  getArrMenuItemForFilterBar = () => {
    const { arrServiceIdStationMenuItemForSelection, searchStation } = this.state;

    const foundStationMenuItemForSelection =
      arrServiceIdStationMenuItemForSelection[0].arrStationMenuItemForSelection.find(
        (stationMenuItemForSelection) => stationMenuItemForSelection.station === searchStation.value
      );
    return foundStationMenuItemForSelection.arrMenuItemForSelection;
  };

  /**
   * render
   */
  render() {
    const {
      arrWasteForCurrentPage,
      numberOfAllWaste,
      numberOfAllImages,
      currentPageNumber,
      perPage,
      arrServiceIdStationMenuItemForSelection,
      arrServiceIdStationForSelection,
      showLoading,
      showFilterBar,
      submitSnackbarOpen,
      startDateTime,
      endDateTime,
      searchMenuItem,
      searchStation,
      errorOccurred,
      errorMessage,
      showServicePrompt,
      isAirline,
    } = this.state;
    const {
      selectedRestaurantName,
      selectedLocationName,
      selectedServiceName,
      isServiceSelected,
      pageType,
    } = this.props;

    return (
      <React.Fragment>
        {!isServiceSelected && (
          <h1>
            You have yet to select a restaurant / location / service. Please select then try again.
          </h1>
        )}

        {isServiceSelected && errorOccurred && <h1>{errorMessage}</h1>}
        {isServiceSelected && !errorOccurred && (
          <div>
            <ActionBar
              title={pageType === 'TAGGED' ? 'Tagged images' : 'Untagged images'}
              numberOfAllWaste={numberOfAllWaste}
              numberOfAllImages={numberOfAllImages}
              changePageNumber={this.changePageNumber}
              submitUpdatedWasteForCurrentPage={this.submitUpdatedWasteForCurrentPage}
              currentPageNumber={currentPageNumber}
              perPage={perPage}
              startDateTime={startDateTime}
              endDateTime={endDateTime}
              changeDateRange={this.changeDateRange}
              resetDateRangeFilter={this.resetDateRangeFilter}
            />

            {/* Filter bar */}
            {pageType === 'TAGGED' && (
              <FilterGroup>
                <Button onClick={this.toggleFilterBar}>
                  <SearchLogo src={searchLogo} alt={'Filter Waste'} />
                </Button>
                {showFilterBar && (
                  <div>
                    <StyledCardHeaders>
                      <InputLabel>Filter Station</InputLabel>
                      <StyledSelect
                        value={searchStation}
                        options={this.getArrayByServiceId(
                          arrServiceIdStationForSelection,
                          'arrStationForSelection',
                          -1
                        )}
                        onChange={this.changeStationDropDownForFilterBar}
                        isSearchable={true}
                      />
                    </StyledCardHeaders>
                    <StyledCardHeaders>
                      <InputLabel>Filter Menu Item</InputLabel>
                      <StyledSelect
                        value={searchMenuItem}
                        options={this.getArrMenuItemForFilterBar()}
                        onChange={this.changeMenuItemDropDownForFilterBar}
                        isSearchable={true}
                      />
                    </StyledCardHeaders>
                    <StyledCardHeaders>
                      <Button variant="outlined" onClick={this.resetFilterForStationAndMenuItem}>
                        Reset Filter For Station and/or Menu Item
                      </Button>
                    </StyledCardHeaders>
                  </div>
                )}
              </FilterGroup>
            )}

            {/* Waste cards */}
            {!showLoading && (
              <Container>
                <Grid container spacing={3}>
                  {/* Render Airline card if selected company is an airline else renders non-airline card*/}
                  {isAirline
                    ? arrWasteForCurrentPage.map((wasteCard, index) => (
                        <Grid key={index} item xs={3}>
                          <AirlineCard
                            waste={wasteCard}
                            wasteCardIndex={index}
                            arrMenuItem={this.getArrayByServiceId(
                              arrServiceIdStationMenuItemForSelection,
                              'arrMenuItem',
                              wasteCard.serviceId
                            )}
                            arrStationMenuItemForSelection={this.getArrayByServiceId(
                              arrServiceIdStationMenuItemForSelection,
                              'arrStationMenuItemForSelection',
                              wasteCard.serviceId
                            )}
                            changeWasteParameterValue={this.changeWasteParameterValue}
                            pageType={pageType}
                          />
                        </Grid>
                      ))
                    : arrWasteForCurrentPage.map((wasteCard, index) => (
                        <Grid key={index} item xs={3}>
                          <CardContainer
                            restaurant={selectedRestaurantName}
                            location={selectedLocationName}
                            service={selectedServiceName}
                            waste={wasteCard}
                            wasteCardIndex={index}
                            arrMenuItem={this.getArrayByServiceId(
                              arrServiceIdStationMenuItemForSelection,
                              'arrMenuItem',
                              wasteCard.serviceId
                            )}
                            arrStationMenuItemForSelection={this.getArrayByServiceId(
                              arrServiceIdStationMenuItemForSelection,
                              'arrStationMenuItemForSelection',
                              wasteCard.serviceId
                            )}
                            arrStationForSelection={this.getArrayByServiceId(
                              arrServiceIdStationForSelection,
                              'arrStationForSelection',
                              wasteCard.serviceId
                            )}
                            changeWasteParameterValue={this.changeWasteParameterValue}
                            pageType={pageType}
                          />
                        </Grid>
                      ))}
                </Grid>
              </Container>
            )}
            {showLoading && <StyledCircularProgress />}
          </div>
        )}

        {/* Snackbar */}
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={submitSnackbarOpen}
          autoHideDuration={6000}
          onClose={this.closeSubmitSnackbar}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">Submitted</span>}
          action={[
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={this.closeSubmitSnackbar}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
        {/* Service modal prompt for untagged page */}
        {pageType === 'UNTAGGED' && (
          <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            open={showServicePrompt}
            onClose={this.closeServicePrompt}
          >
            <DialogTitle>Session Timeout</DialogTitle>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-slide-description"
                style={{ color: 'rgba(0, 0, 0, 0.65)' }}
              >
                Your session for this service is coming to an end. Would you like to extend it?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.setExtend} color="primary">
                Yes
              </Button>
              <Button onClick={() => this.setEndSession(true)} color="primary">
                No
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </React.Fragment>
    );
  }
}
